export const generateCSVData = comparisons => {
  const data = []
  const headers = [
    { label: 'First Name', key: 'firstName' },
    { label: 'Last Name', key: 'lastName' },
    { label: 'Email', key: 'email' },
    { label: 'Email Status', key: 'emailStatus' },
    { label: 'Email Sent', key: 'emailSent' },
    { label: 'Email Opened', key: 'emailOpened' },
    { label: 'Email Bounced', key: 'emailBounced' },
    { label: 'Email Replied', key: 'emailReplied' },
    { label: 'Seniority', key: 'seniority' },
    { label: 'Department', key: 'department' },
    { label: 'Work Direct Phone', key: 'workDirectPhone' },
    { label: 'Home Phone', key: 'homePhone' },
    { label: 'Company Phone', key: 'companyPhone' },
    { label: 'Corporate Phone', key: 'corporatePhone' },
    { label: 'Industry', key: 'industry' },
    { label: 'Country', key: 'country' },
    { label: 'Company Address', key: 'companyAddress' },
    { label: 'Website', key: 'website' },
    { label: 'Company LinkedIn', key: 'companyLinkedIn' },
    { label: 'Annual Revenue', key: 'annualRevenue' },
    { label: 'Title', key: 'title' },
    { label: 'Contact', key: 'contact' },
    { label: 'Company', key: 'company' }
  ]
  comparisons?.forEach(comparison => {
    data.push(...comparison.matchedRecords)
  })
  // console.log(data)
  return { data, headers }
}

export const generateCSV = data => {
  // const data = [];
  const headers = [
    { label: 'First Name', key: 'firstName' },
    { label: 'Last Name', key: 'lastName' },
    { label: 'Title', key: 'title' },
    { label: 'Contact', key: 'contact' },
    { label: 'Company', key: 'company' },
    { label: 'Email', key: 'email' },
    { label: 'Email Status', key: 'emailStatus' },
    { label: 'Email Verified', key: 'verified' },
    { label: 'Email Last Verified', key: 'lastVerified' },
    { label: 'Email Sent', key: 'emailSent' },
    { label: 'Email Opened', key: 'emailOpened' },
    { label: 'Email Bounced', key: 'emailBounced' },
    { label: 'Email Replied', key: 'emailReplied' },
    { label: 'Seniority', key: 'seniority' },
    { label: 'Department', key: 'department' },
    { label: 'Work Direct Phone', key: 'workDirectPhone' },
    { label: 'Home Phone', key: 'homePhone' },
    { label: 'Company Phone', key: 'companyPhone' },
    { label: 'Corporate Phone', key: 'corporatePhone' },
    { label: 'Industry', key: 'industry' },
    { label: 'Country', key: 'country' },
    { label: 'Company Address', key: 'companyAddress' },
    { label: 'Website', key: 'website' },
    { label: 'Company LinkedIn', key: 'companyLinkedIn' },
    { label: 'Annual Revenue', key: 'annualRevenue' }
  ]
  return { data, headers }
}

export const generateCampaignCSV = data => {
  const headers = [
    { label: 'First Name', key: 'firstName' },
    { label: 'Last Name', key: 'lastName' },
    { label: 'Email', key: 'email' }
  ]
  return { data, headers }
}
