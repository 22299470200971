import * as React from 'react'
import Box from '@mui/material/Box'
import Drawer from '@mui/material/Drawer'
import Button from '@mui/material/Button'
import FilterAltIcon from '@mui/icons-material/FilterAlt'

export default function FilterDrawer ({ children, close, setClose, filters }) {
  const [open, setOpen] = React.useState(false)
  const [activeFilters, setActiveFilters] = React.useState(0)

  const toggleDrawer = newOpen => () => {
    setOpen(newOpen)
    setClose(!close)
  }

  React.useEffect(() => {
    if (close === true) {
      setActiveFilters(Object.keys(filters).length)
      setOpen(false)
    }
  }, [close])

  return (
    <div>
      <>
        <Button onClick={toggleDrawer('right', true)}>
          <FilterAltIcon />
          Filters{activeFilters > 0 ? ` (${activeFilters}) active` : ''}
        </Button>
        <Drawer anchor={'right'} open={open} onClose={toggleDrawer(false)}>
          <Box sx={{ width: '750px', backgroundColor: 'white' }}>
            {children}
          </Box>
        </Drawer>
      </>
    </div>
  )
}
