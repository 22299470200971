import { createApi } from '@reduxjs/toolkit/query/react'
import baseQueryWithReauth from '../../utils/basequery'

export const campaignApi = createApi({
  reducerPath: 'campaignApi',
  baseQuery: baseQueryWithReauth,
  tagTypes:['campaigns'],
  endpoints: builder => ({
    createCampaign: builder.mutation({
      query: body => ({
        url: 'campaign',
        method: 'POST',
        body
      })
      ,invalidatesTags:['campaigns']
    }),
    getCampaigns: builder.query({
      query: args => {
        const { page,rows } = args
        return {
          url: 'campaign',
          params: { page,rows }
        }
      },
      providesTags:['campaigns']
    }),
    getSingleCampaign: builder.query({
      query: id => ({
        url: `campaign/${id}`
      })
    }),
    getCampaignUsers: builder.query({
      query: id => ({
        url: `campaign/users/${id}`
      })
    }),
    getApolloMessage: builder.query({
      query: args => {
        const { page, rows } = args
        return {
          url: 'apolo-messages/sequences',
          params: { page, rows },
          method: 'GET'
        }
      },
      transformResponse: response => {
        return response
      }
    }),
    getApolloMessageDetail: builder.query({
      query: args => {
        const { id, page, rows } = args
        return {
          url: `apolo-messages/sequence/${id}`,
          params: { page, rows }
        }
      }
    })
  })
})

export const {
  useCreateCampaignMutation,
  useGetCampaignsQuery,
  useGetCampaignUsersQuery,
  useGetSingleCampaignQuery,
  useGetApolloMessageQuery,
  useGetApolloMessageDetailQuery
} = campaignApi
