import * as React from 'react'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import dayjs from 'dayjs'

export default function BasicDatePicker ({
  label,
  onChange,
  value,
  margin,
  smallWidth
}) {
  const handleDateChange = date => {
    // Format the date using dayjs
    const formattedDate = dayjs(date).format('YYYY-MM-DD')
    // Call the parent onChange with the formatted date
    onChange(formattedDate)
  }
  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      <DemoContainer components={['DatePicker']} sx={margin ? { flex: 1 } : {}}>
        <DatePicker
          label={label}
          slotProps={{ textField: { size: 'small' } }}
          sx={!smallWidth ? { width: '100%' } : {}}
          onChange={handleDateChange}
          value={value && dayjs(value)}
          defaultValue={''}
        />
      </DemoContainer>
    </LocalizationProvider>
  )
}
