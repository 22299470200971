import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import axios from 'axios'
import { setUploadProgress } from './fileSlice'

const getAuthToken = () => {
  const token = localStorage.getItem('token')
  return token ? `Bearer ${token}` : ''
}

export const fileApi = createApi({
  reducerPath: 'fileApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BACKEND_URL,
    prepareHeaders: headers => {
      const token = localStorage.getItem('token')
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }
      return headers
    }
  }),
  tagTypes: ['file', 'tags', 'campaigns','users'],
  endpoints: builder => ({
    uploadFile: builder.mutation({
      queryFn: (payload, api) => {
        return axios.post(
          `${process.env.REACT_APP_BACKEND_URL}upload`,
          payload,
          {
            headers: {
              Authorization: getAuthToken()
            },
            onUploadProgress: upload => {
              const uploadloadProgress = Math.round(
                (100 * upload.loaded) / upload.total
              )
              api.dispatch(setUploadProgress(uploadloadProgress))
            }
          }
        )
      },
      invalidatesTags: ['file', 'tags']
    }),
    uploadLemlistFile: builder.mutation({
      queryFn: (payload, api) => {
        return axios.post(
          `${process.env.REACT_APP_BACKEND_URL}upload/lemlist`,
          payload,
          {
            headers: {
              Authorization: getAuthToken()
            },
            onUploadProgress: upload => {
              const uploadloadProgress = Math.round(
                (100 * upload.loaded) / upload.total
              )
              api.dispatch(setUploadProgress(uploadloadProgress))
            }
          }
        )
      },
      invalidatesTags: ['file', 'tags','users']
    }),
    uploadSendgridFile: builder.mutation({
      queryFn: (payload, api) => {
        return axios.post(
          `${process.env.REACT_APP_BACKEND_URL}upload/sendgrid`,
          payload,
          {
            headers: {
              Authorization: getAuthToken()
            },
            onUploadProgress: upload => {
              const uploadloadProgress = Math.round(
                (100 * upload.loaded) / upload.total
              )
              api.dispatch(setUploadProgress(uploadloadProgress))
            }
          }
        )
      },
      invalidatesTags: ['file', 'campaigns','users']
    }),

    updateApolloFile: builder.mutation({
      queryFn: (payload, api) => {
        return axios.post(
          `${process.env.REACT_APP_BACKEND_URL}upload/apolo`,
          payload,
          {
            headers: {
              Authorization: getAuthToken()
            },
            onUploadProgress: upload => {
              const uploadloadProgress = Math.round(
                (100 * upload.loaded) / upload.total
              )
              api.dispatch(setUploadProgress(uploadloadProgress))
            }
          }
        )
      },
      invalidatesTags: ['file', 'tags','users']
    }),

    updateFile: builder.mutation({
      query: ({ id, formData }) => ({
        url: `uploaded-file/${id}`,
        method: 'PATCH',
        body: formData
      }),
      invalidatesTags: ['file', 'tags']
    }),
    getFiles: builder.query({
      query: args => {
        const { page, rows } = args
        return {
          url: 'uploaded-file',
          params: { page, rows }
        }
      },
      providesTags: ['file'],
      invalidatesTags: ['campaigns']
    }),
    getFileStatuses: builder.query({
      query: args => {
        const { page, rows } = args
        return {
          url: 'uploaded-file/status',
          params: { page, rows }
        }
      },
      providesTags: ['file'],
      invalidatesTags: ['campaigns','users']
    }),
    getFilesBySearch: builder.query({
      query: args => {
        const { page, rows, searchQuery } = args
        console.log(searchQuery)
        return {
          url: `uploaded-file?search=${searchQuery}`,
          params: { page, rows }
        }
      },
      providesTags: ['file', 'tags']
    }),
    getFileById: builder.query({
      query: args => {
        const { id, page, rows } = args
        return {
          url: `uploaded-file/${id}`,
          params: { page, rows }
        }
      },
      providesTags: ['file', 'tags']
    }),
    getFileStatusById: builder.query({
      query: id => ({
        url: `uploaded-file/status/${id}`
      }),
      providesTags: ['file', 'tags']
    }),
    deleteFile: builder.mutation({
      query: id => ({
        url: `uploaded-file/${id}`,
        method: 'DELETE'
      }),
      invalidatesTags: ['file', 'tags']
    })
  })
})

export const {
  useUploadFileMutation,
  useUploadLemlistFileMutation,
  useUploadSendgridFileMutation,
  useUpdateApolloFileMutation,
  useUpdateFileMutation,
  useGetFilesQuery,
  useGetFileStatusesQuery,
  useGetFilesBySearchQuery,
  useGetFileByIdQuery,
  useGetFileStatusByIdQuery,
  useDeleteFileMutation
} = fileApi
