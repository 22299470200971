import * as React from 'react'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import Select from '@mui/material/Select'


export default function FilterSelect ({
  multiple = false,
  label,
  options,
  size,
  value,
  onChange,
  defaultValue,
  width,
  fontSize
}) {
  return (
    <div style={{ width: '100%' }}>
      <FormControl sx={{ width: '100%' }} size={size}>
        <InputLabel
          id='demo-multiple-checkbox-label'
          style={{ fontSize: fontSize }}
        >
          {label}
        </InputLabel>
        <Select
          style={{
            backgroundColor: 'white',
            maxWidth: width ? width : 'auto',
            fontSize: fontSize ? fontSize : 'auto',
            margin: 0
          }}
          labelId='demo-select-small-label'
          id='demo-select-small'
          value={value}
          defaultValue={defaultValue}
          label={label}
          onChange={onChange}
          multiple={multiple}
        >
          {options.map(option => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  )
}
