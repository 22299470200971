import React, { useState } from 'react'
import { Box } from '@mui/material'
import CampaignUserSelection from './UserSelection'
import NewCampaignForm from './CampaignForm'

export default function AddNewCampaign () {
  const [step, setStep] = useState(false)
  const [step1Data, setStep1Data] = useState({})
  const [filters, setFilters] = useState([])
  return (
    <Box sx={{ margin: '.5rem 0' }}>
      {!step ? (
        <CampaignUserSelection
          setStep={setStep}
          setUserIds={setStep1Data}
          userIds={step1Data}
          filters={filters}
          setFilters={setFilters}
        />
      ) : (
        <NewCampaignForm setStep={setStep} step1Data={step1Data} />
      )}
    </Box>
  )
}
