import * as React from 'react'
import Box from '@mui/material/Box'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import { Divider } from '@mui/material'
import { IconButton } from '@mui/material'
import { modalHeader, textStyles } from './styles'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import SecondaryButton from '../Buttons/Secondary'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: 1000,
  bgcolor: 'background.paper',
  border: 'none',
  borderRadius: '8px',
  boxShadow: 24,
  p: 4
}

export default function MUIBasicModal ({
  children,
  title,
  closeIcon,
  btnTitle,
  icon
}) {
  const [open, setOpen] = React.useState(false)
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)

  return (
    <div>
      <SecondaryButton
        text={btnTitle}
        onClick={handleOpen}
        icon={icon}
      />
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <Box sx={modalHeader}>
            <Typography sx={textStyles}>{title}</Typography>
            {closeIcon && (
              <IconButton onClick={handleClose}>
                <HighlightOffIcon />
              </IconButton>
            )}
          </Box>
          <Divider />
          {children}
        </Box>
      </Modal>
    </div>
  )
}
