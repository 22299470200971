import React, { useState } from 'react'
import Page from '../../components/Page'
import UsersList from '../../components/UsersList'

export default function UsersManagement () {
  const [filters, setFilters] = useState([])
  return (
    <Page>
      <UsersList filters={filters} setFilters={setFilters} />
    </Page>
  )
}
