import * as React from "react";
import Box from "@mui/material/Box";
import Collapse from "@mui/material/Collapse";
import IconButton from "@mui/material/IconButton";
import Table from "@mui/material/Table";
import TableBody from "@mui/material/TableBody";
import TableFooter from "@mui/material/TableFooter";
import TablePagination from "@mui/material/TablePagination";
import TableCell from "@mui/material/TableCell";
import TableContainer from "@mui/material/TableContainer";
import TableHead from "@mui/material/TableHead";
import TableRow from "@mui/material/TableRow";
import Paper from "@mui/material/Paper";
import KeyboardArrowDownIcon from "@mui/icons-material/KeyboardArrowDown";
import KeyboardArrowUpIcon from "@mui/icons-material/KeyboardArrowUp";
import { tableCell } from "./styles";
import Checkbox from "@mui/material/Checkbox";
import { useState } from "react";
import { customDateFormat } from "../../../utils/helperFunctions";
import { Tooltip } from "@mui/material";
import { TextDialog } from "../TextDialog";

function Row({
  row,
  header,
  handleCheckboxChange,
  selectedItems,
  showSelection,
}) {
  const [open, setOpen] = React.useState(false);

  return (
    <>
      {row && (
        <React.Fragment>
          <TableRow sx={{ "& > *": { borderBottom: "unset" } }}>
            {showSelection ? (
              <TableCell>
                <Checkbox
                  onChange={(event) => handleCheckboxChange(event, row)}
                  checked={selectedItems.some(
                    (selectedItem) => selectedItem.id === row.id
                  )}
                />
              </TableCell>
            ) : row?.sequenceData && !showSelection ? (
              <TableCell>
                <IconButton
                  aria-label="expand row"
                  size="small"
                  onClick={() => setOpen(!open)}
                >
                  {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              </TableCell>
            ) : (
              <TableCell></TableCell>
            )}
            <TableCell sx={tableCell} align="left">
              {row?.id}
            </TableCell>
            <TableCell sx={tableCell} align="left">
              {row?.fromEmail}
            </TableCell>
            <TableCell sx={tableCell} align="left">
              {row?.toEmail}
            </TableCell>
            <TableCell sx={tableCell} align="left">
              {row?.fromName}
            </TableCell>
            <TableCell sx={tableCell} align="left">
              {row?.toName}
            </TableCell>
            <TableCell sx={tableCell} align="left">
              {row?.toCompany}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              style={{
                paddingBottom: 0,
                paddingTop: 0,
                backgroundColor: "#FAFAFA",
              }}
              colSpan={14}
            >
              <Collapse in={open} timeout="auto" unmountOnExit>
                <Box sx={{ margin: 1 }}>
                  <Table size="small" aria-label="purchases">
                    <TableHead>
                      <TableRow>
                        {header &&
                          header?.map((item, index) => (
                            <TableCell key={index}>{item}</TableCell>
                          ))}
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {row.sequenceData &&
                        row?.sequenceData?.map((sequenceData, idx) => (
                          <TableRow key={idx}>
                            <TableCell component="th" scope="row">
                              {sequenceData.step}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {sequenceData.template}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {sequenceData.subject}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              <TextDialog text={sequenceData.bodyHTML} />
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {customDateFormat(
                                sequenceData.sentAt,
                                "YYYY-MM-DD HH:mm:ss"
                              )}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {customDateFormat(
                                sequenceData.scheduledAt,
                                "YYYY-MM-DD HH:mm:ss"
                              )}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {sequenceData.sent ? "True" : "False"}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {sequenceData.bounce ? "True" : "False"}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {sequenceData.open ? "True" : "False"}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {sequenceData.click ? "True" : "False"}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {sequenceData.unsubscribe ? "True" : "False"}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {sequenceData.replied ? "True" : "False"}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {sequenceData.replyMessage && (
                                <TextDialog
                                  text={sequenceData.replyMessage}
                                  heading="Reply Message"
                                />
                              )}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {sequenceData.contactStage}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {sequenceData.cc}
                            </TableCell>
                            <TableCell component="th" scope="row">
                              {sequenceData.bcc}
                            </TableCell>
                          </TableRow>
                        ))}
                    </TableBody>
                  </Table>
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
        </React.Fragment>
      )}
    </>
  );
}

export function ApolloMessageDetailsCollapsibleTable({
  header = [],
  subHeader = [],
  tableSize,
  rows,
  showSelection,
  handleSelectionChange,
  currentPage,
  totalRecords,
  onPageChange,
  actions,
  onRowsPerPageChange,
  rowsPerPage
}) {
  // handle row selection
  const [selectedItems, setSelectedItems] = useState([]);
  const [selectAll, setSelectAll] = useState(false);

  const handleCheckboxChange = (event, item) => {
    const selectedIndex = selectedItems.findIndex(
      (selectedItem) => selectedItem.id === item.id
    );
    let newSelected = [...selectedItems];

    if (selectedIndex === -1) {
      newSelected = [...selectedItems, item];
    } else {
      newSelected.splice(selectedIndex, 1);
    }

    setSelectedItems(newSelected);
    handleSelectionChange(newSelected.map((item) => item.id));
  };
  const handleSelectAll = (event) => {
    if (event.target.checked) {
      setSelectedItems(rows);
      setSelectAll(true);
      handleSelectionChange(rows.map((item) => item.id));
    } else {
      setSelectedItems([]);
      setSelectAll(false);
      handleSelectionChange([]);
    }
  };

  return (
    <TableContainer
      component={Paper}
      sx={{ boxShadow: "0 0 0 0", marginTop: "1.5rem" }}
    >
      <Table aria-label="collapsible table" size={tableSize}>
        <TableHead>
          <TableRow sx={{ backgroundColor: "lightgray" }}>
            <TableCell>
              {showSelection && (
                <Checkbox
                  indeterminate={
                    selectedItems.length > 0 &&
                    selectedItems.length < rows.length
                  }
                  checked={selectAll}
                  onChange={handleSelectAll}
                />
              )}
            </TableCell>
            {header &&
              header?.map((item, idx) => (
                <TableCell key={idx} sx={tableCell} align="left">
                  {item}
                </TableCell>
              ))}
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, idx) => (
            <Row
              key={idx}
              row={row}
              header={subHeader}
              handleCheckboxChange={handleCheckboxChange}
              selectedItems={selectedItems}
              showSelection={showSelection}
            />
          ))}
        </TableBody>

        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[10, 25,50,100,250,500]}
              colSpan={14}
              count={totalRecords || rows?.length}
              rowsPerPage={rowsPerPage}
              page={currentPage || 0}
              onPageChange={onPageChange}
              onRowsPerPageChange={onRowsPerPageChange}
              slotProps={{
                select: {
                  inputProps: {
                    "aria-label": "rows per page",
                  },
                  native: true,
                },
              }}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  );
}
