import {
  Alert,
  Box,
  Button,
  Container,
  TextField,
  Typography
} from '@mui/material'
import { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { useSelector } from 'react-redux'
import {
  forgotPassText,
  headerText,
  loginForm,
  logo,
  mainContainer,
  welcomeText
} from './styles'
import MuiInput from '../../components/UIKit/Input'
import MuiPassword from '../../components/UIKit/InputPassword'
import { useFormik } from 'formik'
import { SignInValidations } from '../../utils/validation'
import { useLoginMutation } from '../../RTKQuery/AuthService/authApi'

const Login = () => {
  const [login, { isLoading }] = useLoginMutation()
  const formik = useFormik({
    initialValues: {
      firstName: '',
      lastName: '',
      email: '',
      password: '',
      confirmPassword: ''
    },
    validationSchema: SignInValidations,
    onSubmit: async values => {
      try {
        const response = await login(values).unwrap()
      if (response) {
        localStorage.setItem('token',response.access_token)
        navigate('/dashboard')
      }
      } catch (error) {
       if(error.status === 401){
       setError('Incorrect email or password')
       }
      }
    }
  })

  const [error, setError] = useState(''),
    [showPassword, setShowPassword] = useState(false),
    navigate = useNavigate(),
    data = useSelector(state => state.userValidation)

  const handleClickShowPassword = () => setShowPassword(!showPassword)

  useEffect(() => {
    if (data?.error) {
      setError(data?.error)
    } else if (data?.token) {
      navigate('/dashboard')
    }
  }, [data, navigate])
  return (
    <Container sx={mainContainer}>
      <img style={logo} src='gigalabs.png' alt='' />
      <Box sx={loginForm} component='form' onSubmit={formik.handleSubmit}>
        <Box sx={headerText}>
          <Typography sx={welcomeText}>Welcome Back</Typography>
          <Typography
            sx={{ color: 'gray', textAlign: 'center', margin: '0' }}
            variant='caption'
            noWrap
          >
            Enter your credentials to access your account.
          </Typography>
        </Box>
        <MuiInput
          label='Email'
          placeholder='Enter your Email address'
          fieldProps={formik.getFieldProps('username')}
          error={formik.touched.username && Boolean(formik.errors.username)}
          helperText={formik.touched.username && formik.errors.username}
        />
        <MuiPassword
          label='Password'
          fieldProps={formik.getFieldProps('password')}
          error={formik.touched.password && Boolean(formik.errors.password)}
          helperText={formik.touched.confirmPassword && formik.errors.password}
          showPassword={showPassword}
          handleClickShowPassword={handleClickShowPassword}
        />
        <Button
          type='submit'
          color='secondary'
          sx={{ width: '100%', color: 'white' }}
          variant='contained'
        >
          Sign In
        </Button>
        {error && <Alert severity='error'>{error}</Alert>}
      </Box>
      {/* <Typography sx={forgotPassText} variant='p' noWrap>
        Don't have an account yet? <Link to='/signup'>Signup</Link>
      </Typography>
      <Typography sx={forgotPassText} variant='p' noWrap>
        Forgot your Password ? <Link to='/forgot/password'>Reset Password</Link>
      </Typography> */}
    </Container>
  )
}

export default Login
