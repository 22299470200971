const mainDiv = {
    backgroundColor: 'white',
    margin: '0 auto',
    borderRadius: '10px',
    padding: '1.813rem 2.5rem'
  },
  headerDiv = {
    display: 'flex',
    alignItems: 'center',
    flexDirection: { md: 'row', xs: 'column' },
    gap: { md: '0rem', xs: '1rem' }
  },
  buttonsDiv = {
    display: 'flex',
    // justifyContent: 'flex-end',
    alignItems: 'center',
    gap: '1rem',
    marginLeft: 'auto'
  },
  searchField = {
    border: '1px solid #D9D9D9',
    display: 'flex',
    justifyContent: 'space-between',
    alignItems: 'center',
    height: '2.2rem',
    borderRadius: '5px',
    width: '21.875rem',
    marginLeft: { md: '2rem', xs: '0rem' },
    padding: '0px 8px 0px 8px'
  },
  searchInput = {
    border: 'none',
    borderRadius: '5px',
    minHeight: '80%',
    width: '70%'
  }

export { mainDiv, headerDiv, buttonsDiv, searchField, searchInput }
