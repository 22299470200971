import { createSlice } from '@reduxjs/toolkit'

export const fileSlice = createSlice({
  name: 'file',
  initialState: {
    progress: 0,
    file: {},
    fileData: {},
    fileHeaders: [],
    Repeating: []
  },
  reducers: {
    setUploadProgress: (state, action) => {
      state.progress = action.payload
    },
    setUploadedFile: (state, action) => {
      state.file = action.payload
    },
    setUploadedLemlistFile: (state, action) => {
      state.file = action.payload
    },
    setFileData: (state, action) => {
      state.fileData = action.payload
    },
    setFileRepeating: (state, action) => {
      state.Repeating = action.payload
    },
     clearRepeating: (state) => {
      state.Repeating = [];
    },
    setFileHeaders: (state, action) => {
      state.fileHeaders = action.payload
    }
  }
})

export const {
  setUploadProgress,
  setUploadedFile,
  setUploadedLemlistFile,
  setFileData,
  setFileRepeating,
  clearRepeating,
  setFileHeaders
} = fileSlice.actions
export default fileSlice.reducer
