import { Box, Button, Container, Typography } from '@mui/material'
import React, { useEffect, useRef, useState } from 'react'
import {
  headerContainer,
  titleContainer,
  titleStyles
} from './styles'

import { comparisonHeader } from '../../constants/componentConstants'
import { CSVLink } from 'react-csv'
import { generateCSV } from '../../utils/csv'
import { useParams } from 'react-router-dom'
import Loader from '../../components/Loader/Loader'
import { useGetFileByIdQuery } from '../../RTKQuery/FileService/fileApi'
import DetailsTable from '../../components/MergeTable/DetailsTable'
import BackButton from '../../components/UIKit/Buttons/Back'
import Page from '../../components/Page'

const CsvDetails = () => {
  const { id } = useParams()
  const [csvExportData, setCsvExportData] = useState([])
  const [currentPage, setCurrentPage] = useState(0)
  const [rows, setRows] = useState(10)
  const { data, isLoading, refetch } = useGetFileByIdQuery({
    id,
    page: currentPage,
    rows
  })
  const csvData = generateCSV(csvExportData)
  const downloadRef = useRef()

  const handleCsvDownload = () => {
    downloadRef.current.link.click()
  }

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage)
  }
  const handleRowsPerPageChange = event => {
    setRows(parseInt(event.target.value, 10))
    setCurrentPage(0)
  }

  // console.log(data)

  useEffect(() => {
    refetch()
  }, [data])

  return (
    <Page>
      <Box sx={headerContainer}>
        <Box sx={titleContainer}>
          <BackButton />
          <Typography sx={titleStyles}>{data?.file?.originalName}</Typography>
        </Box>
        <Button
          color='secondary'
          variant='contained'
          sx={{ color: 'white' }}
          startIcon={<img src='/download.svg' alt='' />}
          onClick={handleCsvDownload}
        >
          Export CSV
        </Button>
        <CSVLink
          headers={csvData?.headers || []}
          data={csvData?.userData?.users || ''}
          filename={data?.file?.title}
          style={{ display: 'none' }}
          ref={downloadRef}
        />
      </Box>
      {isLoading && !data ? (
        <div className='noData'>
          {' '}
          <Loader />
        </div>
      ) : data?.userData?.users.length > 0 ? (
        <DetailsTable
          label={comparisonHeader}
          data={data?.userData?.users}
          refetchData={refetch}
          setCsvExportData={setCsvExportData}
          exportCsv={true}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          totalRecords={data.userData?.total || 0}
          rowsPerPage={rows}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      ) : (
        <h3 className='noData'>No Record Found</h3>
      )}
    </Page>
  )
}

export default CsvDetails
