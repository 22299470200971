import * as React from 'react'
import OutlinedInput from '@mui/material/OutlinedInput'
import InputLabel from '@mui/material/InputLabel'
import MenuItem from '@mui/material/MenuItem'
import FormControl from '@mui/material/FormControl'
import ListItemText from '@mui/material/ListItemText'
import Select from '@mui/material/Select'
import Checkbox from '@mui/material/Checkbox'

const ITEM_HEIGHT = 48
const ITEM_PADDING_TOP = 8
const MenuProps = {
  PaperProps: {
    style: {
      maxHeight: ITEM_HEIGHT * 4.5 + ITEM_PADDING_TOP,
      width: 250
    }
  }
}

export default function MuiSelect ({
  multiple = false,
  label,
  options,
  size,
  value,
  onChange,
  defaultValue
}) {
  return (
    <div style={{ width: '100%' }}>
      <FormControl sx={{ width: '100%' }} size={size}>
        <InputLabel id='demo-multiple-checkbox-label'>{label}</InputLabel>
        <Select
          labelId='demo-select-small-label'
          id='demo-select-small'
          value={value}
          defaultValue={defaultValue}
          label={label}
          onChange={onChange}
          multiple={multiple}
        >
          {options.map(option => (
            <MenuItem key={option} value={option}>
              {option}
            </MenuItem>
          ))}
        </Select>
      </FormControl>
    </div>
  )
}
