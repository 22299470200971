import React from 'react'
import { headerDiv, leftHeader } from './styles'
import { Box, Typography } from '@mui/material'
import BackButton from '../UIKit/Buttons/Back'

export default function PageComponentHeader ({
  pageTitle,
  children,
  allowBack
}) {
  return (
    <Box sx={headerDiv}>
      <Box sx={leftHeader}>
        {allowBack && <BackButton />}
        <Typography sx={{ fontSize: '1.438rem', fontWeight: '600' }}>
          {pageTitle}
        </Typography>
      </Box>
      <Box sx={{ display: 'flex', gap: '1rem' }}>{children}</Box>
    </Box>
  )
}
