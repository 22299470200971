import React from 'react'
import { MaterialReactTable } from 'material-react-table'
import { Box } from '@mui/material'
import UserCampaignStatsRow from '../../../constants/SubTables/userCampaignStats'

const DataTable = ({
  columns,
  data,
  selectable = false,
  setSorting,
  totalData,
  sorting,
  pagination,
  manualPagination = true,
  setPagination,
  rowSelection = {},
  setRowSelection,
  onSelectionChange
}) => {
  return (
    <MaterialReactTable
      muiTablePaperProps={{
        sx: {
          '& .css-1w3toxa-MuiPaper-root': {
            boxShadow: 'none !important' 
          },
          borderRadius:'8px',
          // backgroundColor:'red'
        }
      }}
      muiTableHeadCellProps={{
        sx: {
          backgroundColor: '#f0f0f0',
          color: '#333',
          fontWeight: 'bold'
        }
      }}
      muiTableBodyCellProps={{
        sx: {
          padding: '8px',
          color: '#333'
        }
      }}
      columns={columns}
      data={data}
      enableRowSelection={selectable ? true : false}
      onRowSelectionChange={setRowSelection}
      getRowId={row => row.id}
      enableSorting
      enableGlobalFilter={false}
      enableColumnFilters={false}
      manualPagination = {manualPagination}
      rowCount={totalData}
      onPaginationChange={setPagination}
      state={{
        pagination,
        sorting,
        rowSelection
      }}
      muiPaginationProps={{ rowsPerPageOptions: [10, 20, 50, 100, 250, 500] }}
      manualSorting
      onSortingChange={setSorting}
      renderDetailPanel={({ row }) =>
        row?.original?.campaigns?.length > 0 ? (
          <Box sx={{ backgroundColor: '#eff0ef' }}>
            <UserCampaignStatsRow row={row} />
          </Box>
        ) : null
      }
    />
  )
}

export default DataTable
