import DuplicateDataModal from '../components/Modals/DuplicateModal'

export const userListColumns = [
  {
    accessorKey: 'firstName',
    header: 'First Name',
    enableSorting: false,
    Cell: ({ row }) =>
      row.original.repeat && row.original.repeat.length > 0 ? (
        <div style={{ display: 'flex', alignItems: 'center' }}>
          <DuplicateDataModal id={row.original.id} />
          <div>{row.original.firstName}</div>
        </div>
      ) : (
        <span>{row.original.firstName}</span>
      )
  },
  {
    accessorKey: 'lastName',
    header: 'Last Name',
    enableSorting: false
  },
  {
    accessorKey: 'email',
    header: 'Email',
    enableSorting: false
  },
  {
    accessorFn: row => {
      // If emailVerification is empty or the verified field is not present, return false
      return row?.emailVerification?.length > 0
        ? row.emailVerification[0].verified
        : false
    },
    header: 'Email Status',
    Cell: ({ cell }) => {
      const value = cell?.getValue()
      return value ? 'Verified' : 'Not Verified'
    },
    enableSorting: false
  },
  {
    accessorKey: 'blackListed',
    header: 'Blacklisted',
    Cell: ({ cell }) => (cell?.getValue() ? 'True' : 'False'),
    enableSorting: false
  },
  {
    accessorKey: 'isRedFlagged',
    header: 'Red Flagged',
    Cell: ({ cell }) => (cell?.getValue() ? 'True' : 'False'),
    enableSorting: false
  },
  {
    accessorKey: 'verified',
    header: 'Verified',
    Cell: ({ cell }) => (cell?.getValue() ? 'True' : 'False'),
    enableSorting: false
  },
  {
    accessorKey: 'total',
    header: 'Total'
    // Cell: ({ cell }) => (!cell.getValue() && 'Null'),
  },
  {
    accessorKey: 'convRate',
    header: 'Conv Rate'
    // Cell: ({ cell }) => (!cell.getValue() && 'Null'),
  },
  {
    accessorKey: 'convValid',
    header: 'Conv Valid'
    // Cell: ({ cell }) => (!cell.getValue() && 'Null'),
  },
  {
    accessorKey: 'totalCampaigns',
    header: 'Campaigns'
    // enableSorting: false
  },
  {
    accessorKey: 'totalSent',
    header: 'Total Sent',
    // enableSorting: false
  },
  {
    accessorKey: 'totalOpens',
    header: 'Total Opens',
    // enableSorting: false
  },
  {
    accessorKey: 'totalClicks',
    header: 'Total Clicks',
    // enableSorting: false
  },
  {
    accessorKey: 'openRate',
    header: 'Open Rate',
    Cell: ({ cell }) => cell?.getValue() + '%',
    // enableSorting: false
  }
]
