export const headerDiv = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between'
}

export const leftHeader = {
  display: 'flex',
  alignItems: 'center',
  gap: '1rem'
}
