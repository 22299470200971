import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'

export const authApi = createApi({
  reducerPath: 'authApi',
  baseQuery: fetchBaseQuery({
    baseUrl: process.env.REACT_APP_BACKEND_URL,
    prepareHeaders: headers => {
      const token = localStorage.getItem('token')
      if (token) {
        headers.set('authorization', `Bearer ${token}`)
      }
      return headers
    }
  }),
  tagTypes: ['user'],
  endpoints: builder => ({
    signup: builder.mutation({
      query: payload => ({
        url: 'auth/signup',
        method: 'POST',
        body: payload
      })
    }),
    login: builder.mutation({
      query: payload => ({
        url: 'auth/login',
        method: 'POST',
        body: payload
      }),
      invalidatesTags: ['user']
    }),
    getLoggedInUser: builder.query({
      query: () => ({
        url: 'admin-users/current',
        method: 'GET'
      }),
      providesTags: ['user']
    })
  })
})

export const { useSignupMutation, useLoginMutation, useGetLoggedInUserQuery } =
  authApi
