import React from 'react'
import { Button } from '@mui/material'

export default function SecondaryButton ({
  onClick,
  text,
  icon,
  variant,
  color,
  fontSize
}) {
  return (
    <Button
      color='secondary'
      variant={variant ? variant : 'contained'}
      onClick={onClick}
      startIcon={icon}
      sx={
        color
          ? { color: color, fontSize: fontSize ? fontSize : 'auto',maxHeight:'40px' }
          : { color: 'white', fontSize: fontSize ? fontSize : 'auto' }
      }
    >
      {text}
    </Button>
  )
}
