export const formContainer = {
  display: 'flex',
  justifyContent:'space-between',
  margin:'1rem 0',
  gap:'3rem'
}

export const row = {
  display: 'flex',
  alignItems: 'center',
  gap: '1rem',
  justifyContent: 'flex-start'
}
