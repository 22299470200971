import { createApi, fetchBaseQuery } from '@reduxjs/toolkit/query/react'
import baseQueryWithReauth from '../../utils/basequery';

export const verificationApi = createApi({
  reducerPath: 'verificationApi',
  baseQuery: baseQueryWithReauth,
  endpoints: builder => ({
    updateEmailVerification: builder.mutation({
      query: (formData) => ({
        url: `email-verification/verify`,
        method: 'POST',
        body: formData
      })
    })
  })
})

export const { useUpdateEmailVerificationMutation } = verificationApi
