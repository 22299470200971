import React, { useEffect } from 'react';
import { Navigate, Outlet, useLocation } from 'react-router-dom';
import Navbar from '../components/Navbar/Navbar';
import { useGetLoggedInUserQuery } from '../RTKQuery/AuthService/authApi';
import { setUser, clearUser } from '../RTKQuery/AuthService/authSlice';
import { useDispatch } from 'react-redux';

const ProtectedRoutes = () => {
  const dispatch = useDispatch();
  const location = useLocation();
  const token = localStorage.getItem('token');

  const { data: user, error, isLoading, refetch } = useGetLoggedInUserQuery();

  useEffect(() => {
    refetch(); // Immediately refetch to check if token is valid
  }, []);

  useEffect(() => {
    if (user) {
      dispatch(setUser(user));
    } else if (error) {
      localStorage.removeItem('token');
      dispatch(clearUser());
    }
  }, [user, error]);

  return token && !error ? (
    <>
      <Navbar /> <Outlet />
    </>
  ) : (
    <Navigate to="/" state={{ from: location }} />
  );
};

export default ProtectedRoutes;
