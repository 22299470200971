import {
  Alert,
  Box,
  Button,
  Divider,
  IconButton,
  Modal,
  Typography
} from '@mui/material'
import React, { useState } from 'react'
import HighlightOffIcon from '@mui/icons-material/HighlightOff'
import {
  cancelContainer,
  repeatContainer,
  divider,
  headerContainer,
  mainContainer,
  mapSelectContainer,
  mappingContainer,
  mappingText,
  textStyles,
  repeatSectionTitle
} from './styles'
import CustomSelect from '../../../../components/CustomSelect/CustomSelect'
import { useSelector } from 'react-redux'
import {
  apolloMessageOptions,
  fileMappingOptions
} from '../../../../constants/componentConstants'
import {
  useCreateApolloCsvDataMutation,
  useCreateCsvDataMutation
} from '../../../../RTKQuery/CsvDataService/csvDataApi'
import { useUpdateFileMutation } from '../../../../RTKQuery/FileService/fileApi'
import Loader from '../../../../components/Loader/Loader'
import MergeTable from '../../../../components/MergeTable'

const MappingModal = ({
  open,
  setOpen,
  setUploading,
  setUploadModal,
  uploadTypeApollo
}) => {
  const { file, fileHeaders, fileData, Repeating } = useSelector(
    state => state.file
  )

  const [mappedValues, setMappedValues] = useState({})
  const [error, setError] = useState('')
  // Merge Table states
  const [showRepeat, setShowRepeat] = useState(null)
  const [showKeep, setShowKeep] = useState(null)
  const [selectedRows, setSelectedRows] = useState([])
  const [selectedValues, setSelectedValues] = useState({})
  const [updatedFile, setUpdatedFile] = useState({})
  // Merge Table states
  const [uniqueData, setUniqueData] = useState(fileData && fileData)
  const [createCsv,{isLoading:csvLoader}] = useCreateCsvDataMutation()
  const [createApolloCsv,{isLoading:apolloLoader}] = useCreateApolloCsvDataMutation()
  const [updateFile] = useUpdateFileMutation()

  const handleClose = () => {
    setOpen(false)
    setUploading(false)
  }

  const handleGoingBack = () => {
    setUploadModal(true)
    setOpen(false)
  }

  // Remove the Repeating from fileData
  const removeRepeating = async () => {
    const uniqueEmails = new Set(Repeating.map(obj => obj.email))
    const filteredArray2 = fileData.filter(obj => !uniqueEmails.has(obj.Email))

    if (filteredArray2.length === 0) {
      handleClose()
      setShowRepeat(false)
    } else {
      const uniqueEmails = new Set(
        Repeating.map(obj => obj.email.toLowerCase())
      )
      const filteredArray2 = uniqueData.filter(
        obj => !uniqueEmails.has(obj.email.toLowerCase())
      )
      try {
        await updateFile(updatedFile)
        await createCsv(filteredArray2)
        setMappedValues({})
        setOpen(false)
      } catch (error) {
        console.log(error)
      }
    }
  }

  // Keep the Repeating
  const keepRepeating = async () => {
    const result = selectedRows.map(row => {
      const updatedValues = {}

      for (const [key, value] of Object.entries(selectedValues)) {
        const [email, header] = key.split('_')

        if (email === row.email) {
          updatedValues[header] = value
        }
      }
      return {
        ...row,
        ...updatedValues,
        uploadedFile: { id: updatedFile.id }
      }
    })
    const uniqueEmails = new Set(Repeating.map(obj => obj.email.toLowerCase()))
    const filteredArray2 = uniqueData.filter(
      obj => !uniqueEmails.has(obj.email.toLowerCase())
    )
    const finalUniqueData = filteredArray2.concat(result)

    try {
      await updateFile(updatedFile)
      await createCsv(finalUniqueData)
      setMappedValues({})
      setOpen(false)
    } catch (error) {
      console.log(error)
    }
  }
  // console.log(mappedValues)

  const handleCsvSave = async () => {
    setError('')
    if (uploadTypeApollo || mappedValues.emailAddress || mappedValues.Email) {
      setUploading(false)
      const newMappedCsv = []
      fileData.forEach(row => {
        const newRow = {}
        Object.entries(row).forEach(([key, val]) => {
          if (mappedValues[key]) {
            newRow[mappedValues[key]] = val
          }
        })
        newMappedCsv.push({ ...newRow, uploadedFile: { id: file.id } })
      })
      if (!Repeating.length > 0) {
        try {
          await updateFile({
            id: file.id
            // formData: { tag: tagId, fileNumber }
          })
          uploadTypeApollo
            ? await createApolloCsv(newMappedCsv)
            : await createCsv(newMappedCsv)
          setMappedValues({})
          setOpen(false)
        } catch (error) {
          console.error(error)
        }
      } else {
        setUniqueData(newMappedCsv)
        setUpdatedFile({ id: file.id })
        setShowRepeat(true)
      }
    } else {
      setError('Please Map headers for the file.')
    }
  }

  return (
    <Modal
      open={open}
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      <>
        {!showRepeat ? (
          <Box sx={mainContainer}>
            <Box sx={headerContainer}>
              <Typography sx={textStyles}>
                <img src='document.png' alt='' />
                {file?.originalName}
              </Typography>
              <IconButton onClick={handleClose}>
                <HighlightOffIcon />
              </IconButton>
            </Box>
            <Divider sx={divider} />
            <Box sx={mappingContainer}>
              <Typography sx={mappingText}>File Mapping</Typography>
              {fileHeaders.length > 0 ? (
                fileHeaders.map((parameter, index) => (
                  <Box sx={mapSelectContainer} key={index + parameter}>
                    <CustomSelect
                      value={parameter}
                      placeholder={'Select a File Tag'}
                      label={`Parameter ${index + 1}`}
                      options={fileHeaders}
                      disabled
                      removeArrow
                    />
                    <CustomSelect
                      value={mappedValues[parameter] || ''}
                      setValue={val =>
                        setMappedValues({ ...mappedValues, [parameter]: val })
                      }
                      placeholder={'Select Field Name'}
                      label={'Map To'}
                      options={
                        uploadTypeApollo
                          ? apolloMessageOptions
                          : fileMappingOptions
                      }
                    />
                  </Box>
                ))
              ) : (
                <Loader />
              )}
            </Box>
            <Divider sx={divider} />
            <Box sx={cancelContainer}>
              <Button
                color='info'
                variant='contained'
                onClick={handleGoingBack}
              >
                Go Back
              </Button>
              <Box>
                <Button
                  color='info'
                  variant='contained'
                  onClick={handleClose}
                  sx={{ marginRight: '1rem' }}
                >
                  Cancel
                </Button>
                <Button
                  color='primary'
                  variant='contained'
                  onClick={handleCsvSave}
                >
                  {csvLoader || apolloLoader ? <Loader size={30} thickness={4}/> : 'Map and Save'}
                </Button>
              </Box>
            </Box>
            {error && <Alert severity='error'>{error}</Alert>}
          </Box>
        ) : showRepeat ? (
          <Box sx={repeatContainer}>
            <div style={{ backgroundColor: 'white' }}>
              <div style={repeatSectionTitle}>You have overlapping records</div>
              {Repeating.length > 0 && uniqueData && (
                <MergeTable
                  fileHeaders={fileHeaders}
                  previous={Repeating}
                  current={uniqueData}
                  setShowButton={setShowKeep}
                  selectedRows={selectedRows}
                  setSelectedRows={setSelectedRows}
                  selectedValues={selectedValues}
                  setSelectedValues={setSelectedValues}
                  reversedMapping={mappedValues}
                />
              )}
              <Divider />
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  paddingTop: '3%',
                  justifyContent: 'flex-end',
                  gap: '2rem',
                  marginLeft: 'auto'
                }}
              >
                <Button
                  onClick={keepRepeating}
                  variant='contained'
                  disabled={!showKeep}
                >
                  Keep changes and Save
                </Button>
                <Button
                  onClick={removeRepeating}
                  variant='contained'
                  disabled={showKeep}
                >
                  Discard All Repeating Records
                </Button>
              </div>
            </div>
          </Box>
        ) : (
          <Loader />
        )}
      </>
    </Modal>
  )
}

export default MappingModal
