import React, { useState } from "react";
import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
} from "@mui/material";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css"; // Import styles for react-quill

export const TextDialog = ({ text, heading = "Body Message" }) => {
  const [open, setOpen] = useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  // Quill editor configurations for read-only mode
  const editorModules = {
    toolbar: false,
    clipboard: {
      matchVisual: false, // Disable clipboard matching
    },
  };
  const editorFormats = [];

  const stripHtmlTags = (bodyHTML) => {
    const parser = new DOMParser();
    const doc = parser.parseFromString(bodyHTML, "text/html");
    const bodyText = doc.body.textContent || "";
    return bodyText;
  };

  return (
    <div>
      <div
        style={{
          maxHeight: "3em",
          overflow: "hidden",
          textOverflow: "ellipsis",
        }}
      >
        {stripHtmlTags(text)}
      </div>
      <Button variant="outlined" size="small" onClick={handleClickOpen}>
        Read More
      </Button>
      <Dialog open={open} onClose={handleClose} maxWidth="md" fullWidth>
        <DialogTitle>{heading}</DialogTitle>
        <DialogContent>
          <ReactQuill
            value={text}
            readOnly={true}
            modules={editorModules}
            formats={editorFormats}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleClose}>Close</Button>
        </DialogActions>
      </Dialog>
    </div>
  );
};
