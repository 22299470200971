import { configureStore } from '@reduxjs/toolkit'
import { fileApi } from './FileService/fileApi'
import fileSlice from './FileService/fileSlice'
import csvDataSlice from './CsvDataService/csvDataSlice'
import { setupListeners } from '@reduxjs/toolkit/query'
import { tagsApi } from './TagsService/tagsApi'
import { csvDataApi } from './CsvDataService/csvDataApi'
import { comparisonApi } from './ComparisonService/ComparisonApi'
import { verificationApi } from './EmailVerificationService/verificationApi'
import { campaignApi } from './CampaignService/campaignApi'
import { authApi } from './AuthService/authApi'
import authSlice from './AuthService/authSlice'

export const store = configureStore({
  reducer: {
    [fileApi.reducerPath]: fileApi.reducer,
    [csvDataApi.reducerPath]: csvDataApi.reducer,
    [tagsApi.reducerPath]: tagsApi.reducer,
    [comparisonApi.reducerPath]: comparisonApi.reducer,
    [verificationApi.reducerPath]: verificationApi.reducer,
    [campaignApi.reducerPath]:campaignApi.reducer,
    [authApi.reducerPath]:authApi.reducer,
    file: fileSlice,
    csvData: csvDataSlice,
    auth:authSlice
  },
  middleware: getDefaultMiddleware =>
    getDefaultMiddleware().concat(
      fileApi.middleware,
      csvDataApi.middleware,
      tagsApi.middleware,
      comparisonApi.middleware,
      verificationApi.middleware,
      campaignApi.middleware,
      authApi.middleware
    )
})

setupListeners(store.dispatch)
