import React from 'react'
import { Button } from '@mui/material'

export default function PrimaryButton ({ onClick, text, icon, disabled }) {
  return (
    <Button
      color='primary'
      variant='contained'
      onClick={onClick}
      startIcon={icon}
      disabled={disabled}
    >
      {text}
    </Button>
  )
}
