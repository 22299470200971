import React from 'react'
import Page from '../../components/Page'
import FilesList from '../../components/FilesList'

export default function Files () {
  return (
    <Page>
      <FilesList/>
    </Page>
  )
}
