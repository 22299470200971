import React from 'react'
import { Button } from '@mui/material'
import { useNavigate } from 'react-router-dom'
import ChevronLeftIcon from '@mui/icons-material/ChevronLeft'

export default function BackButton () {
  const navigate = useNavigate()
  return (
    <Button onClick={() => navigate(-1)} sx={{ color: 'gray' }}>
      <ChevronLeftIcon />
      Back
    </Button>
  )
}
