import { object, string, ref } from "yup";

export const SaveFilterValidation = object().shape({
  saveFilter: string()
    .transform((value) => value.trim())
    .required("Filter name is required")
    .min(3, "Save filter title must be atleast 3 characters")
    .max(255, "Save filter title must not exceed 255 characters"),
});

export const SignupValidations = object({
    firstName: string().required("First Name is required"),
    lastName: string().required("Last Name is required"),
    email: string().email("Invalid email address").required("Email is required"),
    password: string().required("Password is required"),
    confirmPassword: string()
      .oneOf([ref('password'), null], 'Passwords must match')
      .required('Confirm Password is required'),
  });

export const SignInValidations = object({
  username: string().email('Invalid email address').required('Email is required'),
  password: string().required('Password is required')
})
