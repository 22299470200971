import { CircularProgress, Container } from '@mui/material'
import React from 'react'

const Loader = ({ size, thickness }) => {
  return (
    <Container sx={{ display: 'flex' }}>
      <CircularProgress
        size={size ? size : 50}
        thickness={thickness ? thickness : 7}
        sx={{ margin: '0 auto' }}
      />
    </Container>
  )
}

export default Loader
