const mainDiv = {
    minWidth: '90%',
    backgroundColor: 'white',
    margin: 'auto',
    borderRadius: '10px',
    padding: '1rem'
  },
  headerDiv = {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'space-between'
  },
  buttonsDiv = {
    display: 'flex',
    justifyContent: 'flex-end',
    gap:'1rem',
    flexGrow: '1'
  }

export { mainDiv, headerDiv, buttonsDiv }
