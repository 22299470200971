import * as React from 'react'
import Box from '@mui/material/Box'
import Collapse from '@mui/material/Collapse'
import IconButton from '@mui/material/IconButton'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableFooter from '@mui/material/TableFooter'
import TablePagination from '@mui/material/TablePagination'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import KeyboardArrowDownIcon from '@mui/icons-material/KeyboardArrowDown'
import KeyboardArrowUpIcon from '@mui/icons-material/KeyboardArrowUp'
import { tableCell } from './styles'
import Checkbox from '@mui/material/Checkbox'
import { useState } from 'react'
import { customDateFormat } from '../../../utils/helperFunctions'
import { Tooltip } from '@mui/material'

function Row ({
  row,
  header,
  handleCheckboxChange,
  selectedItems,
  showSelection
}) {
  const [open, setOpen] = React.useState(false)

  return (
    <>
      {row && (
        <React.Fragment>
          <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
            {showSelection ? (
              <TableCell>
                <Checkbox
                  onChange={event => handleCheckboxChange(event, row)}
                  checked={selectedItems.some(
                    selectedItem => selectedItem.id === row.id
                  )}
                />
              </TableCell>
            ) : row?.campaigns &&
              row?.campaigns[0]?.lemlistData?.length > 0 &&
              !showSelection ? (
              <TableCell>
                <IconButton
                  aria-label='expand row'
                  size='small'
                  onClick={() => setOpen(!open)}
                >
                  {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                </IconButton>
              </TableCell>
            ) : (
              <TableCell></TableCell>
            )}
            <TableCell sx={tableCell} align='left'>
              {row?.id}
            </TableCell>
            <TableCell sx={tableCell} align='left'>
              {row?.firstName}
            </TableCell>
            <TableCell sx={tableCell} align='left'>
              {row?.lastName}
            </TableCell>
            <TableCell sx={tableCell} align='left'>
              {row?.email}
            </TableCell>
            <TableCell sx={tableCell} align='left'>
              {row?.emailVerification[0]?.verified
                ? 'Verified'
                : 'Not Verified' || 'Not Verified'}
            </TableCell>
            <TableCell sx={tableCell} align='left'>
              {row?.contact}
            </TableCell>
            <TableCell sx={tableCell} align='left'>
              {row?.title}
            </TableCell>
            <TableCell sx={tableCell} align='left'>
              {row?.company}
            </TableCell>
            <TableCell sx={tableCell} align='left'>
              {row?.companyAddress}
            </TableCell>
            <TableCell sx={tableCell} align='left'>
              {row?.department}
            </TableCell>
            <TableCell sx={tableCell} align='left'>
              {row?.seniority}
            </TableCell>
            <TableCell sx={tableCell} align='left'>
              {row?.campaigns?.length}
            </TableCell>
            {row.campaigns &&
              row?.campaigns[0]?.lemlistData?.length > 0 &&
              showSelection && (
                <TableCell>
                  <IconButton
                    aria-label='expand row'
                    size='small'
                    onClick={() => setOpen(!open)}
                  >
                    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                  </IconButton>
                </TableCell>
              )}
          </TableRow>
          <TableRow>
            <TableCell
              style={{
                paddingBottom: 0,
                paddingTop: 0,
                backgroundColor: '#FAFAFA'
              }}
              colSpan={14}
            >
              <Collapse in={open} timeout='auto' unmountOnExit>
                <Box sx={{ margin: 1 }}>
                  <Table size='small' aria-label='purchases'>
                    <TableHead>
                      <TableRow>
                        {header &&
                          header?.map((item, index) => (
                            <TableCell key={index}>{item}</TableCell>
                          ))}
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {/* {console.log(row)} */}
                      {row.campaigns &&
                        row?.campaigns?.map((campaign, idx) =>
                          campaign.lemlistData[0]?.lemlistAnalytics.map(
                            data => (
                              <TableRow
                                key={data.id + '-row' + campaign.id + '-' + idx}
                              >
                                <TableCell component='th' scope='row'>
                                  {campaign.id}
                                </TableCell>
                                <TableCell component='th' scope='row'>
                                  {campaign.campaignTitle}
                                </TableCell>
                                <TableCell component='th' scope='row'>
                                  {data.event}
                                </TableCell>
                                <TableCell component='th' scope='row'>
                                  {customDateFormat(
                                    data.eventTime,
                                    'YYYY-MM-DD HH:mm:ss'
                                  )}
                                </TableCell>

                                <TableCell component='th' scope='row'>
                                  {customDateFormat(
                                    data.sentAt,
                                    'YYYY-MM-DD HH:mm:ss'
                                  )}
                                </TableCell>

                                <TableCell component='th' scope='row'>
                                  {customDateFormat(
                                    data.repliedAt,
                                    'YYYY-MM-DD HH:mm:ss'
                                  )}
                                </TableCell>

                                <TableCell component='th' scope='row'>
                                  {customDateFormat(
                                    data.openedAt,
                                    'YYYY-MM-DD HH:mm:ss'
                                  )}
                                </TableCell>

                                <TableCell component='th' scope='row'>
                                  {data.clickedAt}
                                </TableCell>
                                <TableCell component='th' scope='row'>
                                  <a target='_blank' href={data.clickedLink}>
                                    {data.clickedLink}
                                  </a>
                                </TableCell>
                                <TableCell component='th' scope='row'>
                                  {data.linkedinVisitAt}
                                </TableCell>
                                <TableCell component='th' scope='row'>
                                  {data.linkedinOpenedAt}
                                </TableCell>
                                <TableCell component='th' scope='row'>
                                  {data.callDoneAt}
                                </TableCell>
                              </TableRow>
                            )
                          )
                        )}
                    </TableBody>
                  </Table>
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
        </React.Fragment>
      )}
    </>
  )
}

function CampaignsRow ({
  row,
  header,
  handleCheckboxChange,
  selectedItems,
  showSelection,
  actions
}) {
  const [open, setOpen] = React.useState(false)

  return (
    <>
      {row && (
        <React.Fragment>
          <TableRow sx={{ '& > *': { borderBottom: 'unset' } }}>
            <TableCell></TableCell>
            <TableCell sx={tableCell} align='left'>
              {row?.id}
            </TableCell>
            <TableCell sx={tableCell} align='left'>
              {row?.sequenceName}
            </TableCell>

            <TableCell
              style={{ display: 'flex', gap: '.3rem', border: 'none' }}
            >
              <>{React.cloneElement(actions, { id: row.id })}</>
              {(row.totalSent || row.totalProcessed > 0) && (
                <Tooltip title='View stats'>
                  <IconButton
                    aria-label='expand row'
                    size='small'
                    onClick={() => setOpen(!open)}
                  >
                    {open ? <KeyboardArrowUpIcon /> : <KeyboardArrowDownIcon />}
                  </IconButton>
                </Tooltip>
              )}
            </TableCell>
          </TableRow>
          <TableRow>
            <TableCell
              style={{
                paddingBottom: 0,
                paddingTop: 0,
                backgroundColor: '#FAFAFA'
              }}
              colSpan={16}
            >
              <Collapse in={open} timeout='auto' unmountOnExit>
                <Box sx={{ margin: 1 }}>
                  <Table size='small' aria-label='purchases'>
                    <TableHead>
                      <TableRow>
                        {header &&
                          header?.map((item, index) => (
                            <TableCell key={index}>{item}</TableCell>
                          ))}
                      </TableRow>
                    </TableHead>

                    <TableBody>
                      {/* {console.log(row)} */}
                      {(row.totalSent || row.totalProcessed > 0) && (
                        <>
                          <TableRow key={row.id + 'camaign'}>
                            <TableCell component='th' scope='row'>
                              {row.bounced}
                            </TableCell>
                            <TableCell component='th' scope='row'>
                              {row.totalSent}
                            </TableCell>
                            <TableCell component='th' scope='row'>
                              {row.totalOpen}
                            </TableCell>
                            <TableCell component='th' scope='row'>
                              {row.replies}
                            </TableCell>
                            <TableCell component='th' scope='row'>
                              {row.clicks}
                            </TableCell>
                            <TableCell component='th' scope='row'>
                              {row.openRate}%
                            </TableCell>
                            <TableCell component='th' scope='row'>
                              {row.clickRate}%
                            </TableCell>
                            <TableCell component='th' scope='row'>
                              {row.repliedRate}%
                            </TableCell>
                          </TableRow>
                        </>
                      )}
                    </TableBody>
                  </Table>
                </Box>
              </Collapse>
            </TableCell>
          </TableRow>
        </React.Fragment>
      )}
    </>
  )
}

export function ApolloMessagesCollapsibleTable ({
  header = [],
  subHeader = [],
  tableSize,
  rows,
  showSelection,
  handleSelectionChange,
  currentPage,
  totalRecords,
  onPageChange,
  forCampaigns = false,
  actions,
  rowsPerPage,
  onRowsPerPageChange
}) {
  // handle row selection
  const [selectedItems, setSelectedItems] = useState([])
  const [selectAll, setSelectAll] = useState(false)

  const handleCheckboxChange = (event, item) => {
    const selectedIndex = selectedItems.findIndex(
      selectedItem => selectedItem.id === item.id
    )
    let newSelected = [...selectedItems]

    if (selectedIndex === -1) {
      newSelected = [...selectedItems, item]
    } else {
      newSelected.splice(selectedIndex, 1)
    }

    setSelectedItems(newSelected)
    handleSelectionChange(newSelected.map(item => item.id))
  }
  const handleSelectAll = event => {
    if (event.target.checked) {
      setSelectedItems(rows)
      setSelectAll(true)
      handleSelectionChange(rows.map(item => item.id))
    } else {
      setSelectedItems([])
      setSelectAll(false)
      handleSelectionChange([])
    }
  }

  return (
    <TableContainer
      component={Paper}
      sx={{ boxShadow: '0 0 0 0', marginTop: '1.5rem' }}
    >
      <Table aria-label='collapsible table' size={tableSize}>
        <TableHead>
          <TableRow sx={{ backgroundColor: 'lightgray' }}>
            <TableCell>
              {showSelection && (
                <Checkbox
                  indeterminate={
                    selectedItems.length > 0 &&
                    selectedItems.length < rows.length
                  }
                  checked={selectAll}
                  onChange={handleSelectAll}
                />
              )}
            </TableCell>
            {header &&
              header?.map((item, idx) => (
                <TableCell key={item + '-' + idx} sx={tableCell} align='left'>
                  {item}
                </TableCell>
              ))}
            <TableCell />
          </TableRow>
        </TableHead>
        <TableBody>
          {rows.map((row, idx) => {
            if (forCampaigns) {
              return (
                <CampaignsRow
                  key={idx + '-campaigns-' + idx}
                  row={row}
                  header={subHeader}
                  selectedItems={selectedItems}
                  showSelection={showSelection}
                  actions={actions}
                />
              )
            } else {
              return (
                <Row
                  key={idx + '-row-' + idx}
                  row={row}
                  header={subHeader}
                  handleCheckboxChange={handleCheckboxChange}
                  selectedItems={selectedItems}
                  showSelection={showSelection}
                />
              )
            }
          })}
        </TableBody>

        <TableFooter>
          <TableRow>
            <TablePagination
              rowsPerPageOptions={[10,25,50,100,250,500]}
              colSpan={14}
              count={totalRecords || rows?.length}
              rowsPerPage={rowsPerPage}
              page={currentPage}
              onPageChange={onPageChange}
              onRowsPerPageChange={onRowsPerPageChange}
              slotProps={{
                select: {
                  inputProps: {
                    'aria-label': 'rows per page'
                  },
                  native: true
                }
              }}
            />
          </TableRow>
        </TableFooter>
      </Table>
    </TableContainer>
  )
}
