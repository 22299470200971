import React from 'react'
import DOMPurify from 'dompurify'

const SanitizedHTML = ({ html, fontFam }) => {
  const sanitizedHTML = DOMPurify.sanitize(html)

  return (
    <div
      dangerouslySetInnerHTML={{ __html: sanitizedHTML }}
      style={fontFam ? { fontFamily: fontFam } : {}}
    />
  )
}

export default SanitizedHTML
