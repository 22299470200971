import React, { useEffect, useState } from 'react'
import {
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  Paper,
  Select,
  MenuItem
} from '@mui/material'

export const Header = {
  'FIRST NAME': 'firstName',
  'LAST NAME': 'lastName',
  EMAIL: 'email',
  VERIFIED: 'isVerified',
  BLACKLISTED: 'blackListed',
  'RED FLAGGED': 'isRedFlagged',
  'CREATED AT': 'createdAt'
}

const DuplicationTable = ({ userRecords, setSelectedRows, selectedRows }) => {
  // const [selectedValues, setSelectedValues] = useState({})

  const handleSelectChange = (field, value) => {
    setSelectedRows(prevValues => ({
      ...prevValues,
      [field]: value
    }))
  }

  // Aggregate all possible values for each field
  const aggregatedValues = Object.keys(Header).reduce((acc, header) => {
    const fieldKey = Header[header]
    const allValues = userRecords.reduce((values, user) => {
      const userFieldValues = Array.isArray(user[fieldKey])
        ? user[fieldKey]
        : [user[fieldKey]]
      return [...values, ...userFieldValues]
    }, [])

    // Convert boolean values to strings "True" and "False"
    if (['isVerified', 'blackListed', 'isRedFlagged'].includes(fieldKey)) {
      acc[fieldKey] = [
        ...new Set(allValues.map(val => (val ? 'True' : 'False')))
      ]
    } else {
      acc[fieldKey] = [...new Set(allValues)]
    }
    return acc
  }, {})

  // Initialize selectedRows with iniial values
  useEffect(() => {
    const initialSelectedValues = Object.keys(aggregatedValues).reduce((acc, fieldKey) => {
      acc[fieldKey] = aggregatedValues[fieldKey][0] || ''; // Set to first value or empty string
      return acc;
    }, {});
    setSelectedRows(initialSelectedValues);
  }, []);

  return (
    <TableContainer component={Paper}>
      <Table>
        <TableHead>
          <TableRow>
            {Object.keys(Header).map(header => (
              <TableCell key={header}>{header}</TableCell>
            ))}
          </TableRow>
        </TableHead>
        <TableBody>
          <TableRow>
            {Object.keys(Header).map(header => {
              const fieldKey = Header[header]
              const fieldValues = aggregatedValues[fieldKey]
              console.log('field values', fieldValues)

              return (
                <TableCell key={fieldKey}>
                  <Select
                    sx={{ width: '100%' }}
                    size='small'
                    // value={selectedValues[fieldKey] || ''}
                    disabled={fieldValues.length <= 1}
                    defaultValue={fieldValues[0] || ''}
                    onChange={e => handleSelectChange(fieldKey, e.target.value)}
                  >
                    {fieldValues.map((option, optionIndex) => (
                      <MenuItem key={optionIndex} value={option}>
                        {option}
                      </MenuItem>
                    ))}
                  </Select>
                </TableCell>
              )
            })}
          </TableRow>
        </TableBody>
      </Table>
    </TableContainer>
  )
}

export default DuplicationTable
