import * as React from 'react'
import SanitizedHTML from '../../UIKit/SanitizeHtml'
import MUIBasicModal from '../../UIKit/BasicModal'
import EmailIcon from '@mui/icons-material/Email'

export default function EmailTextModal ({ body }) {
  return (
    <MUIBasicModal
      title={'Campaign Body'}
      btnTitle={'Email'}
      closeIcon={true}
      icon={<EmailIcon />}
    >
      <SanitizedHTML html={body || 'Hello world'} />
    </MUIBasicModal>
  )
}
