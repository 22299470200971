import React from 'react'
import { IconButton, InputAdornment, TextField } from '@mui/material'
import { Visibility, VisibilityOff } from '@mui/icons-material'

export default function MuiPassword ({
  size,
  label,
  showPassword,
  handleClickShowPassword,
  handleMouseDownPassword,
  fieldProps,
  helperText,
  error
}) {
  return (
    <TextField
      sx={{ width: '100%', margin: '10px 0' }}
      size={size}
       type={showPassword ? "text" : "password"}
      label={label}
      placeholder='Enter your Password'
      {...fieldProps}
      error={error}
      helperText={helperText}
      InputProps={{
        endAdornment: (
          <InputAdornment position='end'>
            <IconButton
              aria-label='toggle password visibility'
              onClick={handleClickShowPassword}
              onMouseDown={handleMouseDownPassword}
              edge='end'
            >
              {showPassword ? <VisibilityOff /> : <Visibility />}
            </IconButton>
          </InputAdornment>
        )
      }}
    ></TextField>
  )
}
