import dayjs from 'dayjs'

export const changeDateFormat = param => {
  if (!param) {
    return ''
  }

  const date = new Date(param)
  const formattedDate = dayjs(date).format('MMDDYYYY')
  return formattedDate
}

export const customDateFormat = (param, format) => {
  if (!param) {
    return ''
  }

  const date = new Date(param)
  const formattedDate = dayjs(date).format(format)
  return formattedDate
}

export const getlocalDateTime = param => {
  const localDateString = param.toLocaleString()
  return localDateString
}

// Hello World = hello_world
// export function formatString (inputString) {
//   const stringWithUnderscores = inputString.replace(/ /g, '_')
//   const lowercaseString = stringWithUnderscores.toLowerCase()
//   return lowercaseString
// }

export const formatString = inputString => {
  if (typeof inputString === 'string') {
    const stringWithUnderscores = inputString.replace(/ /g, '_')
    const lowercaseString = stringWithUnderscores.toLowerCase()
    return lowercaseString
  }
}
