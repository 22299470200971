import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import Loader from '../Loader/Loader'
import { ApolloMessageDetailsCollapsibleTable } from '../UIKit/ApolloMessageDetailsCollapsibleTable'
import { useGetApolloMessageDetailQuery } from '../../RTKQuery/CampaignService/campaignApi'
import {
  ApolloMessageDetailHeader,
  ApolloMessageDetailStats
} from '../../constants/componentConstants'
import PageComponentHeader from '../PageComponentHeader'

export function ApolloMessageDetailsComponent () {
  const { id } = useParams()
  const [currentPage, setCurrentPage] = useState(0)
  const [rows, setRows] = useState(10)

  const { data, loading: apolloMessageLoading } =
    useGetApolloMessageDetailQuery({
      id: id,
      rows,
      page: currentPage
    })

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage)
  }
  const handleRowsPerPageChange = event => {
    setRows(parseInt(event.target.value, 10))
    setCurrentPage(0)
  }
  return (
    <>
      <PageComponentHeader
        pageTitle={data && data.resp[0].name}
        allowBack={true}
      ></PageComponentHeader>
      {apolloMessageLoading ? (
        <div className='noData'>
          <Loader />
        </div>
      ) : data ? (
        <ApolloMessageDetailsCollapsibleTable
          header={ApolloMessageDetailHeader}
          subHeader={ApolloMessageDetailStats}
          rows={data.resp}
          // handleSelectionChange={setSelectedsers}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          totalRecords={data.totalRecords || 0}
          rowsPerPage={rows}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      ) : (
        <h3 className='noData'>No Record Found</h3>
      )}
    </>
  )
}
