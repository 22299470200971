import React from 'react'
// import PrimaryButton from '../Primary'
// import PeopleAltIcon from '@mui/icons-material/PeopleAlt'
import { Button } from '@mui/material'

const ExportButton = ({ onClick, isLoading }) => {
  return (
    <Button
      variant='contained'
      color='primary'
      onClick={onClick}
      disabled={isLoading}
    >
      {isLoading ? 'Exporting...' : 'Export Users'}
    </Button>
  )
}

export default ExportButton
