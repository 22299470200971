import { Box, IconButton } from '@mui/material'
import React, { useState } from 'react'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'
import DeleteModal from '../../DeleteModal/DeleteModal'
import { useDeleteFileMutation } from '../../../RTKQuery/FileService/fileApi'

const ActionButtons = (data) => {
  const [deleteFile] = useDeleteFileMutation()
  const [deleteModal, setDeleteModal] = useState(false)

  const handleDelete = async () => {
    try {
      await deleteFile(data.id)
    } catch (error) {
      console.log(error)
    }
    setDeleteModal(false)
  }
  
  return (
    <Box>
      <IconButton onClick={() => setDeleteModal(true)}>
        <DeleteOutlineOutlinedIcon />
      </IconButton>
      <DeleteModal
        open={deleteModal}
        setOpen={setDeleteModal}
        name={data && data.name}
        // type={'Tag'}
        handleDelete={handleDelete}
      />
    </Box>
  )
}

export default ActionButtons
