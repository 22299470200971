import React, { useEffect, useRef, useState } from 'react'
import { useParams } from 'react-router-dom'
import Loader from '../Loader/Loader'
import { useGetCampaignUsersQuery } from '../../RTKQuery/CsvDataService/csvDataApi'
import SelectionCollapsibleTable from '../UIKit/SelectionCollapsibleTable'
import {
  userCampaignStats,
  campaignUserHeader
} from '../../constants/componentConstants'

import { CSVLink } from 'react-csv'
import UploadCampaignStats from '../UIKit/Buttons/UploadCampaignStats'
import FilterDrawer from '../UIKit/Drawer'
import ConditionalFilters from '../ConditionalFilters'
import EmailTextModal from '../Modals/EmailTextModal'
import { generateCampaignCSV } from '../../utils/csv'
import PageComponentHeader from '../PageComponentHeader'
import { useLazyExportCampaignUsersQuery } from '../../RTKQuery/CsvDataService/csvDataApi'
import ExportButton from '../UIKit/Buttons/Export'

export default function CampaignDetailsComponent () {
  const { id } = useParams()
  const [users, setUsers] = useState([])
  const [currentPage, setCurrentPage] = useState(0)
  const [email, setEmail] = useState('')
  const [filters, setFilters] = useState([])
  const csvData = generateCampaignCSV(users)
  const downloadRef = useRef()
  const [rows, setRows] = useState(10)
  // Filters
  const [closeFilterMenu, setCloseFilterMenu] = useState(true)
  const [filterCount, setFilterCount] = useState(1)
  const [conditionCount, setConditionCount] = useState(0)
  const [subConditionCount, setSubConditionCount] = useState(0)
  const [triggerExport, { isLoading: exportLoading }] =
    useLazyExportCampaignUsersQuery()

  const [filterData, setFilterData] = useState([
    {
      id: 'f-1',
      first: true,
      conditions: [
        {
          id: 'f-10',
          first: true,
          subConditions: [],
          filter: 'f-1'
        }
      ]
    }
  ])
  const { data, loading } = useGetCampaignUsersQuery({
    id: id,
    page: currentPage,
    rows,
    filters: JSON.stringify(filters)
  })

  const handleApplyFilters = () => {
    setCurrentPage(currentPage)
    setFilters(filterData)
    setCloseFilterMenu(true)
  }

  const handleResetFilters = () => {
    setCurrentPage(currentPage)
    setFilterData([
      {
        id: 'f-1',
        first: true,
        conditions: [
          {
            id: 'f-10',
            first: true,
            subConditions: [],
            filter: 'f-1'
          }
        ]
      }
    ])
    setFilters([])
    setCloseFilterMenu(true)
  }

  const handleExport = async () => {
    try {
      await triggerExport({ id, filters }).unwrap()
    } catch (error) {
      console.error('Error exporting users:', error)
    }
  }
  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage)
  }
  const handleRowsPerPageChange = event => {
    setRows(parseInt(event.target.value, 10))
    setCurrentPage(0)
  }
  // data && console.log(data)

  useEffect(() => {
    if (data) {
      setUsers(data.users)
      // setData(data.users)
      setEmail(data?.users[0]?.campaigns[0]?.campaignBody)
    }
  }, [id, data])
  return (
    <>
      <PageComponentHeader
        pageTitle={'Campaign # ' + id + ' Users'}
        allowBack={true}
      >
        {data?.users[0]?.campaigns[0]?.campaign === 'lemlist' && (
          <EmailTextModal body={email} />
        )}
        <ExportButton onClick={handleExport} isLoading={exportLoading} />
        {data?.users[0]?.campaigns[0]?.campaign === 'lemlist' && (
          <UploadCampaignStats id={id} />
        )}

        <FilterDrawer
          close={closeFilterMenu}
          setClose={setCloseFilterMenu}
          filters={filters}
        >
          <ConditionalFilters
            campaignUsers={true}
            onReset={handleResetFilters}
            onSubmit={handleApplyFilters}
            filtersArray={filterData}
            setFiltersArray={setFilterData}
            setCloseFilterMenu={setCloseFilterMenu}
            filterCount={filterCount}
            setFilterCount={setFilterCount}
            conditionCount={conditionCount}
            setConditionCount={setConditionCount}
            subConditionCount={subConditionCount}
            setSubConditionCount={setSubConditionCount}
          />
        </FilterDrawer>
      </PageComponentHeader>
      {loading ? (
        <div className='noData'>
          <Loader />
        </div>
      ) : data ? (
        <SelectionCollapsibleTable
          header={campaignUserHeader}
          subHeader={userCampaignStats}
          rows={users}
          // handleSelectionChange={setSelectedsers}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          totalRecords={data.total || 0}
          rowsPerPage={rows}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      ) : (
        <h3 className='noData'>No Record Found</h3>
      )}
      <CSVLink
        headers={csvData?.headers || []}
        data={csvData?.data || ''}
        filename={'Campaign'}
        style={{ display: 'none' }}
        ref={downloadRef}
      />
    </>
  )
}
