import * as React from 'react'
import Box from '@mui/material/Box'
import Button from '@mui/material/Button'
import Typography from '@mui/material/Typography'
import Modal from '@mui/material/Modal'
import SimCardAlertIcon from '@mui/icons-material/SimCardAlert'
import { Divider, IconButton } from '@mui/material'
import CloseIcon from '@mui/icons-material/Close'
import DetailsTable from '../../MergeTable/DetailsTable'
import { comparisonHeader } from '../../../constants/componentConstants'
import {
  useGetCsvDuplicateDataQuery,
  useDeleteDuplicateDataMutation,
  useUpdateDuplicateDataMutation
} from '../../../RTKQuery/CsvDataService/csvDataApi'
import { Tooltip } from '@mui/material'
import DuplicationTable from '../../MergeTable/DuplicateTable'

const style = {
  position: 'absolute',
  top: '50%',
  left: '50%',
  transform: 'translate(-50%, -50%)',
  width: '90%',
  bgcolor: 'background.paper',
  borderRadius: '5px',
  boxShadow: 24,
  p: 4
}

const header = {
  display: 'flex',
  alignItems: 'center',
  justifyContent: 'space-between'
}

const closeButton = {
  color: 'gray'
}

export default function DuplicateDataModal ({ id, refetchData }) {
  const [open, setOpen] = React.useState(false)
  const [selectedRows, setSelectedRows] = React.useState([])
  const handleOpen = () => setOpen(true)
  const handleClose = () => setOpen(false)
  const { data, isLoading, refetch } = useGetCsvDuplicateDataQuery(id)
  // const [deleteMutation] = useDeleteDuplicateDataMutation()
  const [updateDuplicateData] = useUpdateDuplicateDataMutation()

  // const handleCheckboxChange = (csvId, event) => {
  //   const isSelected = event.target.checked
  //   if (isSelected) {
  //     setToDelete(prevState => [...prevState, csvId])
  //   } else {
  //     setToDelete(prevState => prevState.filter(id => id !== csvId))
  //   }
  // }

  const handleSaveButtonClick = async () => {
    // console.log(selectedRows)
    const ids = data?.map(item => item.id) || []
    try {
      await updateDuplicateData({
        user: id,
        duplicates: ids,
        data: selectedRows
      })
      setOpen(false)
    } catch (error) {
      console.error('Delete Mutation Error:', error)
    }
  }
  const handleKeepAllButtonClick = () => {
    // refetchData()
    setOpen(false)
  }

  return (
    <div>
      <Tooltip title='View Duplicates'>
        <IconButton onClick={handleOpen} color='warning'>
          <SimCardAlertIcon />
        </IconButton>
      </Tooltip>
      <Modal
        open={open}
        onClose={handleClose}
        aria-labelledby='modal-modal-title'
        aria-describedby='modal-modal-description'
      >
        <Box sx={style}>
          <Box sx={header}>
            <Typography id='modal-modal-title' variant='h6' component='h2'>
              Manage duplicate records
            </Typography>
          </Box>
          <Divider />
          <Box>
            <DuplicationTable
              userRecords={data || []}
              setSelectedRows={setSelectedRows}
              selectedRows={selectedRows}
            />
          </Box>

          <Box sx={{ display: 'flex', gap: '1rem', marginTop: '1rem' }}>
            <Button
              variant='contained'
              color='warning'
              onClick={handleKeepAllButtonClick}
              style={{ color: 'white' }}
            >
              Cancel
            </Button>
            <Button
              variant='contained'
              color='primary'
              onClick={handleSaveButtonClick}
              style={{ color: 'white' }}
            >
              Save
            </Button>
          </Box>
        </Box>
      </Modal>
    </div>
  )
}
