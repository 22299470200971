const mainContainer = {
    minHeight: "12.125em",
    backgroundColor: "white",
    width: "30%",
    margin: "0 auto",
    // padding: "1em",
    borderRadius: "8px",
  },
  headerContainer = {
    display: "flex",
    flexGrow: "1",
    justifyContent: "space-between",
    alignItems: "center",
  },
  deleteContainer = {
    display: "flex",
    justifyContent: "center",
    marginTop: ".5rem",
  },
  imageContainer = {
    height: "8rem",
    display: "flex",
    flexDirection: "column",
    justifyContent: "center",
    alignItems: "center",
    borderRadius: "8px",
    // color:"white",
    // backgroundColor: "#d32f2f",
  },
  bottomContainer = {
    display: "flex",
    marginTop:".8rem",
    flexGrow: "1",
    justifyContent: "center",
  },
  deleteLabel = {
    fontSize: "1.4375rem",
    fontStyle: "normal",
    fontWeight: 500,
    lineHeight: "150%",
    marginBottom: "0.5rem",
  },
  divider = { margin: "1.5rem 0rem" },
  textStyles = {
    fontSize: "16px",
    // lineHeight: "2.156rem",
    // fontWeight: "500",
    margin: "1rem 0rem",
  };

export {
  mainContainer,
  imageContainer,
  bottomContainer,
  headerContainer,
  divider,
  textStyles,
  deleteContainer,
  deleteLabel,
};
