import React from 'react'
import Page from '../../components/Page'
import PageComponentHeader from '../../components/PageComponentHeader'
import AddNewCampaign from '../../components/AddNewCampaign'

export default function AddCampaign () {
  return (
    <Page>
      <PageComponentHeader allowBack={false} pageTitle={'New Campaign'} />
      <AddNewCampaign />
    </Page>
  )
}
