import React, { useEffect, useState } from 'react'
import PageComponentHeader from '../PageComponentHeader'
import Loader from '../Loader/Loader'
import CustomTable from '../CustomTable/CustomTable'
import { filesTableHeader } from '../../constants/componentConstants'
import { useGetFileStatusesQuery } from '../../RTKQuery/FileService/fileApi'
import ActionButtons from './ActionButton'

export default function FilesList () {
  const [rows, setRows] = useState(10)
  const [currentPage, setCurrentPage] = useState(0)
  const { data, isLoading, refetch } = useGetFileStatusesQuery({
    page: currentPage,
    rows
  })
  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage)
  }
  const handleRowsPerPageChange = event => {
    setRows(parseInt(event.target.value, 10))
    setCurrentPage(0)
  }

  useEffect(() => {
    refetch()
  }, [])
  return (
    <>
      <PageComponentHeader pageTitle={'My Files'} />
      {isLoading ? (
        <div className='noData'>
          <Loader />
        </div>
      ) : data && data.length > 0 ? (
        <CustomTable
          data={data}
          label={filesTableHeader}
          view
          action={<ActionButtons />}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          totalRecords={data.total || 0}
          rowsPerPage={rows}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      ) : (
        <h3 className='noData'>No Record Found</h3>
      )}
    </>
  )
}
