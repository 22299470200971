const mainContainer = {
    minHeight: '42.125em',
    backgroundColor: 'white',
    width: '80%',
    margin: '0 auto',
    padding: '1.5em 2.5em',
    borderRadius: '10px',
    overflow:'hidden'
  },
  repeatContainer = {
    minHeight: '22.125em',
    backgroundColor: 'white',
    width: '80%',
    margin: '0 auto',
    padding: '1.5em 2.5em',
    borderRadius: '10px'
  },
  headerContainer = {
    display: 'flex',
    flexGrow: '1',
    justifyContent: 'space-between',
    alignItems: 'center'
  },
  tagContainer = {
    display: 'flex',
    justifyContent: 'space-between'
  },
  cancelContainer = {
    display: 'flex',
    flexGrow: '1',
    justifyContent: 'space-between'
  },
  divider = { margin: '1.5rem 0rem' },
  textStyles = {
    display: 'flex',
    justifyContent: 'center',
    alignItems: 'center',
    fontSize: '1.438rem',
    lineHeight: '2.156rem',
    fontWeight: '500',
    margin: '1rem 0rem'
  },
  mappingText = {
    fontSize: '1.1875rem',
    fontWeight: 500,
    lineHeight: '150%',
    marginBottom: '1rem'
  },
  mappingContainer = {
    maxHeight: '24.55rem',
    overflow: 'auto',
    paddingRight: '1rem'
  },
  repeatSectionTitle = {
    width: '100%',
    margin: 'auto',
    // borderRadius: '8px',
    padding: '1rem 0',
    textAlign: 'center',
    backgroundColor: '#EE2D3C',
    color: 'white',
    fontWeight: 700,
    fontSize: '1.3rem'
  },
  mapSelectContainer = {
    display: 'flex',
    justifyContent: 'space-between'
  }

export {
  mainContainer,
  repeatContainer,
  cancelContainer,
  headerContainer,
  divider,
  textStyles,
  tagContainer,
  mappingText,
  mappingContainer,
  mapSelectContainer,
  repeatSectionTitle
}
