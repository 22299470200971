import * as React from 'react'
import Table from '@mui/material/Table'
import TableBody from '@mui/material/TableBody'
import TableCell from '@mui/material/TableCell'
import TableContainer from '@mui/material/TableContainer'
import TableHead from '@mui/material/TableHead'
import TableRow from '@mui/material/TableRow'
import Paper from '@mui/material/Paper'
import {
  Select,
  MenuItem,
  NativeSelect,
  Checkbox,
  FormControlLabel,
  Button
} from '@mui/material'
import { useState } from 'react'
import { useEffect } from 'react'

export default function MergeTable ({
  fileHeaders,
  previous,
  current,
  setShowButton,
  selectedRows,
  setSelectedRows,
  selectedValues,
  setSelectedValues,
  reversedMapping
}) {
  const [data, setData] = useState([])
  const [selectAll, setSelectAll] = useState(false)

  function combineArrays (previousArray, currentArray) {
    const combinedArray = []

    for (const currentEntry of currentArray) {
      const matchingPreviousEntry = previousArray.find(
        previousEntry => previousEntry.email === currentEntry.email
      )

      if (matchingPreviousEntry) {
        const combinedEntry = {
          previous: matchingPreviousEntry,
          current: { ...currentEntry }
        }
        if ('uploadedFile' in combinedEntry.current) {
          delete combinedEntry.current.uploadedFile
        }

        combinedArray.push(combinedEntry)
      }
    }

    setData(combinedArray)
  }

  const handleCheckboxChange = (email, currentRow) => {
    const isSelected = selectedRows?.some(row => row?.email === email)

    let updatedSelectedRows

    if (isSelected) {
      updatedSelectedRows = selectedRows.filter(row => row?.email !== email)
    } else {
      updatedSelectedRows = [...selectedRows, { ...currentRow }]
    }

    setSelectedRows(updatedSelectedRows)
    setShowButton(updatedSelectedRows.length > 0)
  }

  const handleSelectChange = (header, value, email) => {
    const finalHeader = reversedMapping[header]
    // console.log(finalHeader)
    setSelectedValues(prevSelectedValues => ({
      ...prevSelectedValues,
      [`${email}_${reversedMapping[header]}`]: value
    }))
    // console.log(`${email}_${reversedMapping[header]}`)
  }

  const handleCheckboxAllChange = () => {
    setSelectAll(!selectAll)
    const updatedSelectedRows = selectAll ? [] : [...current]
    setSelectedRows(updatedSelectedRows)
    setShowButton(updatedSelectedRows.length > 0)
  }

  const renderTableHead = () => (
    <TableHead>
      <TableRow>
        <TableCell>
          <FormControlLabel
            control={
              <Checkbox
                checked={selectAll}
                onChange={handleCheckboxAllChange}
              />
            }
          />
          {/* <strong>Select</strong> */}
        </TableCell>
        {fileHeaders?.map((heading, index) => (
          <TableCell key={index}>
            {reversedMapping[heading]?.toUpperCase() || heading}
          </TableCell>
        ))}
      </TableRow>
    </TableHead>
  )

  const renderTableBody = () => (
    <TableBody>
      {data &&
        data.map((row, index) => (
          <TableRow
            key={index}
            sx={{ '&:last-child td, &:last-child th': { border: 0 } }}
          >
            <TableCell>
              <FormControlLabel
                control={
                  <Checkbox
                    checked={
                      selectedRows.some(
                        selectedRow => selectedRow?.email === row.current.email
                      ) || selectAll
                    }
                    onChange={() =>
                      handleCheckboxChange(row.current.email, row.current)
                    }
                  />
                }
              />
            </TableCell>
            {fileHeaders.map(header => (
              <TableCell align='left' key={header}>
                {row.previous[reversedMapping[header]] ===
                row.current[reversedMapping[header]] ? (
                  <span>{row.previous[reversedMapping[header]] || null}</span>
                ) : (
                  <Select
                    style={{ minWidth: '10rem' }}
                    value={
                      selectedValues[`${row.previous?.email}_${[reversedMapping[header]]}`] ||
                      row.current[reversedMapping[header]]
                    }
                    onChange={e => {
                      handleSelectChange(
                        header,
                        e.target.value,
                        row.previous?.email
                      )
                    }}
                  >
                    <MenuItem value={row.previous[reversedMapping[header]]}>
                      {row.previous[reversedMapping[header]]}
                      <span style={{ fontSize: '12px', color: 'gray' }}>
                        {' '}
                        (old)
                      </span>
                    </MenuItem>
                    <MenuItem
                      value={row.current[reversedMapping[header]] || null}
                    >
                      {row.current[reversedMapping[header]] || null}{' '}
                      <span style={{ fontSize: '12px', color: 'gray' }}>
                        {' '}
                        (new)
                      </span>
                    </MenuItem>
                  </Select>
                )}
              </TableCell>
            ))}
          </TableRow>
        ))}
    </TableBody>
  )

  useEffect(() => {
    previous && current && combineArrays(previous, current)
  }, [])

  return (
    <div>
      <TableContainer component={Paper} sx={{ maxHeight: 440 }}>
        <Table sx={{ minWidth: 650 }} stickyHeader aria-label='sticky table'>
          {renderTableHead()}
          {renderTableBody()}
        </Table>
      </TableContainer>
    </div>
  )
}
