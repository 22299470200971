const tableContainer = {
    width: '100%',
    marginTop: '1.5rem'
  },
  noRecord = {
    margin: 'auto',
    fontSize: '1.5em',
    fontWeight: '600',
    textAlign: 'center'
  },
  statusText = {
    display: 'flex',
    fontSize: '0.9375rem',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '150%'
  },
  statusIcon = {
    width: '15%'
  },
  tableHeader = {
    backgroundColor: '#D9D9D9',
    width: '100%'
  },
  headerText = { color: '#808080' },
  tagText = {
    backgroundColor: '#F3F3F3',
    borderRadius: '0.375rem',
    padding: '0.25rem 0.5rem',
    fontSize: '0.75rem',
    fontStyle: 'normal',
    fontWeight: 500,
    lineHeight: '150%',
    marginRight: '0.625rem'
  }

export {
  noRecord,
  statusText,
  statusIcon,
  tableHeader,
  tableContainer,
  headerText,
  tagText
}
