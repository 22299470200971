import React from 'react'
import { TextField } from '@mui/material'

export default function MuiInput ({
  label,
  size,
  placeholder,
  fieldProps,
  helperText,
  error
}) {
  return (
    <TextField
      sx={{ width: '100%', margin: '10px 0' }}
      label={label}
      size={size}
      placeholder={placeholder}
      {...fieldProps}
      error={error}
      helperText={helperText}
    ></TextField>
  )
}
