import React from 'react'
import Campaigns from '../../components/CampaignsList'
import Page from '../../components/Page'

export default function CampaignList () {
  return (
    <Page>
      <Campaigns />
    </Page>
  )
}
