import React, { useEffect } from 'react'
import Page from '../../components/Page'
import { ApolloMessagesList } from '../../components/ApolloMessagesList'
import { useSelector } from 'react-redux'
import { useNavigate } from 'react-router-dom'

export function ApolloMessages () {
  const navigate = useNavigate()
  const user = useSelector(state => state.auth?.user)

  useEffect(() => {
    if (user && user.permissions) {
      const hasPermission = user.permissions.some(
        permission => permission.id === 1
      )
      if (!hasPermission) {
        navigate('/dashboard')
      }
    }
  }, [user])

  return (
    <Page>
      <ApolloMessagesList />
    </Page>
  )
}
