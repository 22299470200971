import * as React from 'react';
import TextField from '@mui/material/TextField';
import Autocomplete from '@mui/material/Autocomplete';

export default function MuiAutoComplete({
  multiple = false,
  label,
  options,
  size,
  value,
  onChange,
  defaultValue,
  width,
  fontSize,
  id
}) {
  return (
    <div style={{ width: '100%',fontSize:fontSize }}>
      <Autocomplete
        multiple={multiple}
        id={id}
        options={options}
        value={value}
        defaultValue={defaultValue}
        onChange={(event, newValue) => {
          onChange(newValue);
        }}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label}
            size={size}
            style={{
              backgroundColor: 'white',
              width: width ? width : 'auto',
              margin: 0
            }}
            inputProps={{
              ...params.inputProps,
              style: { fontSize: fontSize, borderRadius: 0 }
            }}
            InputLabelProps={{
              style: { fontSize: fontSize }
            }}
            margin='normal'
          />
        )}
      />
    </div>
  );
}
