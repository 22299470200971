import React from 'react'
import { Button } from '@mui/material'
import template from '../../../../assets/csv/template.csv'
import SimCardDownloadIcon from '@mui/icons-material/SimCardDownload'

const CsvTemplateButton = () => {
  return (
    <Button
      href={template}
      variant='contained'
      color='primary'
      sx={{ gap: '.5rem' }}
    >
      <SimCardDownloadIcon />
      Csv Template
    </Button>
  )
}

export default CsvTemplateButton
