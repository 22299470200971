import React from 'react'
import Page from '../../components/Page'
import CampaignDetailsComponent from '../../components/CampaignDetails'

export default function CampaignDetails () {
  return (
    <Page>
      <CampaignDetailsComponent />
    </Page>
  )
}
