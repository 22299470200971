import React, { useState, useEffect } from 'react'
import {
  ApolloMessagesHeader,
  ApoloMessagesSubHeader
} from '../../constants/componentConstants'
import ActionButton from '../CampaignsList/ActionButton'

import Loader from '../Loader/Loader'
import { ApolloMessagesCollapsibleTable } from '../UIKit/ApolloMessagesCollapsibleTable'
import PageComponentHeader from '../PageComponentHeader'
import './style.css'
import { useGetApolloMessageQuery } from '../../RTKQuery/CampaignService/campaignApi'

export function ApolloMessagesList () {
  const [currentPage, setCurrentPage] = useState(0)
  const [reload, setReload] = useState(false)
  const [rows, setRows] = useState(10)

  const { data: apolloMessagesListData, isLoading: loading } =
    useGetApolloMessageQuery({
      page: currentPage,
      rows
    })

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage)
  }
  const handleRowsPerPageChange = event => {
    setRows(parseInt(event.target.value, 10))
    setCurrentPage(0)
  }

  const apolloPage = true

  return (
    <>
      <PageComponentHeader pageTitle={'Apollo Messages'}></PageComponentHeader>
      {loading ? (
        <div>
          <Loader />
        </div>
      ) : apolloMessagesListData && apolloMessagesListData.length > 0 ? (
        <ApolloMessagesCollapsibleTable
          header={ApolloMessagesHeader}
          subHeader={ApoloMessagesSubHeader}
          rows={apolloMessagesListData}
          forCampaigns={true}
          actions={<ActionButton refetch={setReload} apolloPage={apolloPage} />}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          totalRecords={apolloMessagesListData.length || 0}
          rowsPerPage={rows}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      ) : (
        <h3 className='noData'>No Record Found</h3>
      )}
    </>
  )
}
