import { createBrowserRouter } from 'react-router-dom'
import Dashboard from '../pages/Dashboard/Dashboard'
import Login from '../pages/Login/Login'
import ForgotPassword from '../pages/ForgotPassword/ForgotPassword'
import CsvDetails from '../pages/CsvDetails/CsvDetails'
import CampaignList from '../pages/Campaigns'
import AddCampaign from '../pages/AddCampaign'
import CampaignDetails from '../pages/CampaignDetails'
import UsersManagement from '../pages/Users'
import { ApolloMessages } from '../pages/ApolloMessages'
import { ApolloMessageDetails } from '../pages/ApolloMessageDetails'
// import Signup from '../pages/Signup/signup'
import ProtectedRoutes from './privateRoutes'
import PublicRoutes from './publicRoutes'
import Files from '../pages/Files'


const Router = createBrowserRouter([
  {
    element: <PublicRoutes />,
    children: [
      {
        path: '/',
        element: <Login />
      },
      {
        path: '/forgot/password',
        element: <ForgotPassword />
      }
    ]
  },
  {
    element: <ProtectedRoutes />,
    children: [
      {
        path: 'dashboard',
        element: <Dashboard />
      },
      {
        path: 'csv/details/:id',
        element: <CsvDetails />
      },
      {
        path: 'campaigns',
        element: <CampaignList />
      },
      {
        path: 'campaigns/new',
        element: <AddCampaign />
      },
      {
        path: 'campaign/details/:id',
        element: <CampaignDetails />
      },
      {
        path: 'users',
        element: <UsersManagement />
      },
      {
        path: 'apollomessages',
        element: <ApolloMessages />
      },
      {
        path: 'apolo-messages/sequence/:id',
        element: <ApolloMessageDetails />
      },
      {
        path: 'files',
        element: <Files />
      },
    ]
  }
])

export default Router
