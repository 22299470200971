import React from 'react'
import { Button } from '@mui/material'

export default function WarningButton ({ onClick, text, icon }) {
  return (
    <Button
      color='warning'
      variant='contained'
      onClick={onClick}
      startIcon={icon}
      sx={{ color: 'white' }}
    >
      {text}
    </Button>
  )
}
