export const modalHeader = {
  display: 'flex',
  flexGrow: '1',
  justifyContent: 'space-between',
  alignItems: 'center'
}

export const textStyles = {
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  fontSize: '1.438rem',
  lineHeight: '2.156rem',
  fontWeight: '500',
  margin: '1rem 0rem'
}
