import { Box, Button, Modal, Typography } from '@mui/material'
import React from 'react'
import { mainContainer, textStyles } from './styles'
import { imageContainer } from './styles'
import { bottomContainer } from './styles'
import DeleteOutlineOutlinedIcon from '@mui/icons-material/DeleteOutlineOutlined'

const DeleteModal = ({
  open,
  setOpen,
  name,
  type,
  handleDelete,
  error,
  message = 'Are you sure you want to delete this item?'
}) => {
  const handleClose = () => {
    setOpen(false)
  }

  return (
    <Modal
      open={open}
      sx={{ display: 'flex', alignItems: 'center', justifyContent: 'center' }}
    >
      <Box sx={mainContainer}>
        {/* <Box sx={headerContainer}>
          <Typography sx={textStyles}>Delete {type}</Typography>
          <IconButton onClick={handleClose}>
            <HighlightOffIcon />
          </IconButton>
        </Box> */}
        {/* <Divider sx={divider} /> */}
        <Box sx={imageContainer}>
          <Box
            sx={{
              padding: '.8rem',
              borderRadius: 9999,
              backgroundColor: '#d32f2f',
              display: 'flex',
              justifyContent: 'center'
            }}
          >
            <DeleteOutlineOutlinedIcon sx={{ color: 'white' }} />
          </Box>
          <Typography sx={textStyles}>
            {error ? 'Cannot delete this item' : message}
          </Typography>
        </Box>
        {/* <Box sx={deleteContainer}>
          <Typography sx={deleteLabel}>
            Are you sure you want to delete "{name}" {type}
          </Typography>
        </Box> */}
        {/* <Divider sx={divider} /> */}
        <Box sx={bottomContainer}>
          <Button
            color='info'
            variant='contained'
            onClick={handleClose}
            sx={{ marginRight: '1rem' }}
          >
            Go Back
          </Button>
          {!error && (
            <Button
              variant='contained'
              color='error'
              onClick={handleDelete}
              sx={{ color: 'white' }}
            >
              Delete
            </Button>
          )}
        </Box>
      </Box>
    </Modal>
  )
}

export default DeleteModal
