import React from "react";
import { Box, IconButton } from "@mui/material";
import VisibilityOutlinedIcon from "@mui/icons-material/VisibilityOutlined";
import { useNavigate } from "react-router-dom";
import { Tooltip } from "@mui/material";


const ActionButton = ({ id, apolloPage }) => {
  const navigate = useNavigate();
  const handleClick = () => {
    navigate(
      apolloPage ? `/apolo-messages/sequence/${id}` : `/campaign/details/${id}`
    );
  };

  return (
    <>
      <Box>
        <Tooltip title="View Details">
          <IconButton onClick={handleClick} size="small">
            <VisibilityOutlinedIcon />
          </IconButton>
        </Tooltip>
  
      </Box>
    </>
  );
};

export default ActionButton;
