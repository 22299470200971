import { Padding } from "@mui/icons-material";

export const filterHeader = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
  borderBottom: "1px solid lightgray",
};

export const filterCard = {
  margin: "1rem 0",
  padding: "0 1rem",
  borderRadius: "8px",
  backgroundColor: "#F2F3F3",
};

export const CardHeader = {
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
};

export const saveFilterHeader = {
  display: "flex",
  justifyContent: "space-between",
  position: "absolute",
  right: "-20px",
  top: "-20px",
};

export const container = {
  position: "relative",
};

export const saveFilterLabel = {
  display: "flex",
  alignItems: "center",
  marginTop: "14px",
  gap: "12px",
};
