import React, { useState, useEffect } from 'react'
import {
  CampaignHeader,
  CampaignSubHeader
} from '../../constants/componentConstants'
import ActionButton from './ActionButton'
import { useGetCampaignsQuery } from '../../RTKQuery/CampaignService/campaignApi'
import Loader from '../Loader/Loader'
import SelectionCollapsibleTable from '../UIKit/SelectionCollapsibleTable'
import PageComponentHeader from '../PageComponentHeader'
import { Box, Button } from '@mui/material'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import AddIcon from '@mui/icons-material/Add'
import { useNavigate } from 'react-router-dom'
import Upload from '../../pages/Dashboard/components/Upload/Upload'
import MuiSnackbar from '../UIKit/Snackbar'
import { buttonsDiv } from '../../pages/Campaigns/styles'
import './style.css'

export default function Campaigns () {
  const [currentPage, setCurrentPage] = useState(0)

  // upload props
  const navigate = useNavigate()
  const [uploadModal, setUploadModal] = useState(false)
  const [showMap, setShowMap] = useState(false)
  const [uploading, setUploading] = useState(false)
  const [uploaded, setUploaded] = useState(false)
  const [rows, setRows] = useState(10)

  const {
    data,
    isLoading: loading,
    refetch
  } = useGetCampaignsQuery(
    {
      page: currentPage,
      rows
    }
    // { pollingInterval: 7000 }
  )

  const handlePageChange = (event, newPage) => {
    setCurrentPage(newPage)
  }
  const handleRowsPerPageChange = event => {
    setRows(parseInt(event.target.value, 10))
    setCurrentPage(0)
  }

  const handleUploadModal = bool => {
    setShowMap(bool)
    setUploadModal(true)
  }

  const handleAddNewCampaign = () => {
    navigate('/campaigns/new')
  }

  useEffect(() => {
    refetch()
  }, [data])

  return (
    <>
      <PageComponentHeader pageTitle={'Campaigns'}>
        <Box sx={buttonsDiv}>
          <Button
            startIcon={<AddIcon />}
            color='primary'
            variant='contained'
            sx={{ color: '#F3F3F3' }}
            onClick={handleAddNewCampaign}
          >
            Add Campaign
          </Button>
          <Button
            startIcon={<UploadFileIcon />}
            color='primary'
            variant='contained'
            sx={{ color: '#F3F3F3' }}
            onClick={() => handleUploadModal(false)}
          >
            Upload Sendgrid Stats
          </Button>
        </Box>
      </PageComponentHeader>
      <Upload
        sendGrid={true}
        open={uploadModal}
        setOpen={setUploadModal}
        // setMapModal={setMapModal}
        showMap={showMap}
        setShowMap={setShowMap}
        uploading={uploading}
        setUploading={setUploading}
        setUploaded={setUploaded}
        // setStatusCheck={setStatusCheck}
      />
      <MuiSnackbar
        show={uploaded}
        setShow={setUploaded}
        message={'Successfully uploaded'}
      />
      {loading ? (
        <div className='noData'>
          <Loader />
        </div>
      ) : data?.campaigns.length > 0 ? (
        <SelectionCollapsibleTable
          header={CampaignHeader}
          subHeader={CampaignSubHeader}
          rows={data.campaigns}
          forCampaigns={true}
          actions={<ActionButton />}
          currentPage={currentPage}
          onPageChange={handlePageChange}
          totalRecords={data.total || 0}
          rowsPerPage={rows}
          onRowsPerPageChange={handleRowsPerPageChange}
        />
      ) : (
        <h3 className='noData'>No Record Found</h3>
      )}
    </>
  )
}
