export const gridContainer = {
  width: '100%',
  margin: '1rem 0'
}, 

headerDiv = {
  display: 'flex',
  justifyContent: 'space-between',
  alignItems: 'center',
  gap: '1rem',
  marginLeft: 'auto'
},

filtersContainer = {
  width: '30%',
  margin: '1rem 0',
  display: 'flex',
  alignItems: 'center',
  gap:'1rem'
  // backgroundColor:'gray'
}
