export const routes = [
  'Dashboard',
  // "Report",
  // "Tags Management",
  'Campaigns',
  'Users',
  'Apollo Messages',
  'Files'
]
export const codelabsRoutes = [
  'Dashboard',
  // "Report",
  // "Tags Management",
  'Campaigns',
  'Users',
  // "Apollo Messages",
  'Files'
]
export const fileMappingOptions = [
  { name: 'fullName' },
  { name: 'contact' },
  { name: 'company' },
  { name: 'email' },
  { name: 'mobilePaymentNumber' },
  { name: 'lastLoginIp' },
  { name: 'dateOfBirth' },
  { name: 'blackListed' },
  { name: 'isRedFlagged' },
  { name: 'paypalEmailAddress' },
  { name: 'source' },
  { name: 'state' },
  { name: 'gender' },
  { name: 'language' },
  { name: 'ipMatchedToCountry' },
  { name: 'isVerified' },
  { name: 'sendPromotionalEmail' },
  { name: 'createdAt' },
  { name: 'verifiedAt' },
  { name: 'total' },
  { name: 'finished' },
  { name: 'valid' },
  { name: 'inValid' },
  { name: 'escalated' },
  { name: 'convRate' },
  { name: 'convValid' },
  { name: 'convInvalid' },
  { name: 'emailStatus' },
  { name: 'emailSent' },
  { name: 'emailOpened' },
  { name: 'emailBounced' },
  { name: 'emailReplied' },
  { name: 'seniority' },
  { name: 'department' },
  { name: 'workDirectPhone' },
  { name: 'homePhone' },
  { name: 'companyPhone' },
  { name: 'corporatePhone' },
  { name: 'industry' },
  { name: 'country' },
  { name: 'companyAddress' },
  { name: 'website' },
  { name: 'companyLinkedIn' },
  { name: 'annualRevenue' },
  { name: 'title' }
]
export const apolloMessageOptions = [
  { name: 'sequenceName' },
  { name: 'step' },
  { name: 'template' },
  { name: 'fromEmail' },
  { name: 'toEmail' },
  { name: 'fromName' },
  { name: 'toName' },
  { name: 'subject' },
  { name: 'bodyHTML' },
  { name: 'sentAt' },
  { name: 'scheduledAt' },
  { name: 'cc' },
  { name: 'bcc' },
  { name: 'sent' },
  { name: 'bounce' },
  { name: 'open' },
  { name: 'click' },
  { name: 'unsubscribe' },
  { name: 'replied' },
  { name: 'replyMessage' },
  { name: 'contactStage' },
  { name: 'toCompany' }
]
// export const comparisonHeader = ["FIRSTNAME", "LASTNAME", "CONTACT", "COMPANY"];
export const comparisonHeader = [
  'FIRST NAME',
  'LAST NAME',
  // 'TITLE',
  'EMAIL',
  'EMAIL VERIFIED',
  'EMAIL STATUS',
  'EMAIL LAST VERIFIED',
  // 'SENIORITY',
  // 'DEPARTMENT',
  // 'CONTACT',
  // 'WORK DIRECT PHONE',
  // 'HOME PHONE',
  // 'COMPANY PHONE',
  // 'CORPORATE PHONE',
  // 'INDUSTRY',
  // 'COUNTRY',
  // 'COMPANY',
  // 'COMPANY ADDRESS',
  // 'WEBSITE',
  // 'COMPANY LINKEDIN',
  // 'ANNUAL REVENUE',
  // 'EMAIL SENT',
  // 'EMAIL OPENED',
  // 'EMAIL BOUNCED',
  // 'EMAIL REPLIED',
  'VERIFIED',
  'BLACKLISTED',
  'RED FLAGGED',
  'Created AT'
  // 'File ID'
]

export const Header = {
  'FIRST NAME': 'firstName',
  'LAST NAME': 'lastName',
  // TITLE: 'title',
  EMAIL: 'email',
  'EMAIL VERIFIED': 'verified',
  'EMAIL STATUS': 'state',
  'EMAIL LAST VERIFIED': 'lastVerified',
  // SENIORITY: 'seniority',
  // DEPARTMENT: 'department',
  // CONTACT: 'contact',
  // 'WORK DIRECT PHONE': 'workDirectPhone',
  // 'HOME PHONE': 'homePhone',
  // 'COMPANY PHONE': 'companyPhone',
  // 'CORPORATE PHONE': 'corporatePhone',
  // INDUSTRY: 'industry',
  // COUNTRY: 'country',
  // COMPANY: 'company',
  // 'COMPANY ADDRESS': 'companyAddress',
  // WEBSITE: 'website',
  // 'COMPANY LINKEDIN': 'companyLinkedIn',
  // 'ANNUAL REVENUE': 'annualRevenue',
  // 'EMAIL SENT': 'emailSent',
  // 'EMAIL OPENED': 'emailOpened',
  // 'EMAIL BOUNCED': 'emailBounced',
  // 'EMAIL REPLIED': 'emailReplied',
  VERIFIED: 'isVerified',
  BLACKLISTED: 'blackListed',
  'RED FLAGGED': 'isRedFlagged',
  'CREATED AT': 'createdAt'
  // 'UPDATED AT': 'updatedAt',
  // 'FILE ID': 'fileId'
}

export const dashboardTableHeader = ['DATE UPLOADED', 'FILE NAME', '']
export const filesTableHeader = ['Date Uploaded', 'File Name', 'Status', ' ']

export const mergeTableHeader = [
  'email',
  'firstName',
  'lastName',
  'title',
  'emailStatus',
  'seniority',
  'department',
  'contact',
  'workDirectPhone',
  'homePhone',
  'companyPhone',
  'corporatePhone',
  'industry',
  'country',
  'company',
  'companyAddress',
  'website',
  'companyLinkedIn',
  'annualRevenue',
  'emailSent',
  'emailOpened',
  'emailBounced',
  'emailReplied'
]
export const reportTableHeader = [
  'FILE NUMBER',
  // "TAGS",
  'COMAPRISON DATE',
  'ACTIONS'
]
export const tagsTableHeader = [
  'DATE CREATED',
  'TAG NAME',
  'FILES NUMBER',
  'ACTIONS'
]
export const saveFiltersTableHeader = ['Created At', 'Title', 'ACTIONS']

export const CampaignHeader = [
  // 'ID',
  'Campaign Title',
  'Campaign Subject',
  'Campaign Owner',
  'Industry',
  'Approved',
  'Start Date',
  'End Date',
  'Actions'
]

export const ApolloMessagesHeader = ['ID', 'Sequence Title', 'Actions']
export const ApoloMessagesSubHeader = [
  'Total Bounced',
  'Total Sent',
  'Total Opened',
  'Total Replied',
  'Total Clicks',
  'Open Rate',
  'Click Rate',
  'Replied Rate'
]

export const CampaignSubHeader = [
  'Total Processed',
  'Total Sent',
  'Total Opened',
  'Total Replied',
  'Total Clicks',
  'Open Rate',
  'Click Rate',
  'Replied Rate'
]

export const campaignDetailsHeader = [
  'First Name',
  'Last Name',
  'User Email',

  'Campaign ID',
  'Campaign Title',
  'Campaign Subject',
  'Started At'
]

export const userSelectionHeader = [
  // 'id',
  'First Name',
  'Last Name',
  'Email',
  'Email Status',
  // 'Contact',
  'Blacklisted',
  // "Title",
  // 'Company',
  'Red Flagged',
  'Verified',
  // "Company Address",
  // "Department",
  // "Seniority",
  'Campaigns',
  'Sent',
  'Opens',
  'Clicks',
  'Open rate'
  // "click rate"
]
export const campaignUserHeader = [
  // 'id',
  'First Name',
  'Last Name',
  'Email',
  'Email Status',
  'BlackListed',
  'Red Flagged',
  'Verified',
  // 'Company Address',
  // 'Department',
  // 'Seniority',
  'Campaigns'
  // "Sent",
  // "Opens",
  // "Clicks",
  // "Open rate",
  // "click rate"
]

export const ApolloMessageDetailHeader = [
  'id',
  'From Email',
  'To Email',
  'From Name',
  'To Name',
  'To Company'
]

export const ApolloMessageDetailStats = [
  'Step',
  'Template',
  'Subject',
  'Body HTML',
  'Sent At (PST)',
  'Scheduled At (PST)',
  'Sent',
  'Bounce',
  'Open',
  'Click',
  'Unsubscribe',
  'Replied',
  'Reply Message',
  'Contact Stage',
  'CC',
  'BCC'
]

export const userCampaignStats = [
  // 'campaignId',
  'campaign Title',
  'Event',
  'Event Time',
  // 'processed',
  // 'sent',
  'sentAt',
  // 'replied',
  'repliedAt',
  // 'opened',
  'openedAt',
  // 'interestedAt',
  // 'notInterestedAt',
  // 'bouncedAt',
  // 'unsubscribedAt',
  // 'clicked',
  'clickedAt',
  'clickedLink'
  // 'failedAt',
  // 'failedMessage',
  // 'linkedinVisitAt',
  // 'linkedinVisitFailedAt',
  // 'linkedinInviteDoneAt',
  // 'linkedinInviteFailedAt',
  // 'linkedinInviteAcceptedAt',
  // 'linkedinSentAt',
  // 'linkedinOpenedAt'
  // 'linkedinInterestedAt',
  // 'linkedinNotInterestedAt',
  // 'linkedinSendFailedAt',
  // 'linkedinRepliedAt',
  // 'callDoneAt',
  // 'callInterestedAt',
  // 'callNotInterestedAt',
  // 'apiDoneAt',
  // 'apiInterestedAt',
  // 'apiNotInterestedAt',
  // 'manualDoneAt',
  // 'manualInterestedAt',
  // 'manualNotInterestedAt'
]
