import * as React from 'react'
import { DemoContainer } from '@mui/x-date-pickers/internals/demo'
import { AdapterDayjs } from '@mui/x-date-pickers/AdapterDayjs'
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider'
import { DatePicker } from '@mui/x-date-pickers/DatePicker'
import dayjs from 'dayjs'

export default function FilterDatePicker ({
  label,
  onChange,
  value,
  margin,
  smallWidth,
  width,
  fontSize
}) {
  const handleDateChange = date => {
    // Format the date using dayjs
    const formattedDate = dayjs(date).format('YYYY-MM-DD')
    // Call the parent onChange with the formatted date
    onChange(formattedDate)
  }
  const datePickerStyles = {
    width: width,
    fontSize: fontSize
  }

  return (
    <LocalizationProvider dateAdapter={AdapterDayjs}>
      {/* <DemoContainer components={['DatePicker']}> */}
        <DatePicker
          label={label}
          slotProps={{
            textField: {
              size: 'small',
              sx: {
                width: width,
                maxWidth: '9rem',
                backgroundColor: 'white',
                '.MuiFormLabel-root': { fontSize: fontSize },
                '.MuiInputBase-root': { fontSize: fontSize },
                'MuiFormControl-root': { margin: 0 }
              }
            }
          }}
          sx={{width:'5rem'}}
          onChange={handleDateChange}
          value={value && dayjs(value)}
          defaultValue={''}
        />
      {/* </DemoContainer> */}
    </LocalizationProvider>
  )
}
