import { Box, Typography, Button, Grid, Divider } from '@mui/material'
import React, { useEffect, useState } from 'react'
import PrimaryButton from '../../UIKit/Buttons/Primary'
import SecondaryButton from '../../UIKit/Buttons/Secondary'
import { buttonsContainer } from '../styles'
import { useNavigate } from 'react-router-dom'
import { useGetAllUsersDataQuery } from '../../../RTKQuery/CsvDataService/csvDataApi'
import Loader from '../../Loader/Loader'
import { gridContainer } from './styles'
import { subHeading, subHeadingText } from '../styles'
// import { useDispatch } from 'react-redux'
import FilterDrawer from '../../UIKit/Drawer'
import UploadFileIcon from '@mui/icons-material/UploadFile'
import Upload from './Upload/Upload'
import MappingModal from './MappingModal/MappingModal'
import CompareModal from './CompareModal/CompareModal'

import ConditionalFilters from '../../ConditionalFilters'
import DataTable from '../../UIKit/CollapsibleTable'
import { userListColumns } from '../../../constants/tableColumns'

export default function CampaignUserSelection ({
  setStep,
  setUserIds,
  userIds,
  setFilters,
  filters
}) {
  const navigate = useNavigate()
  // const dispatch = useDispatch()
  const [currentPage, setCurrentPage] = useState(0)
  const [usersData, setUsersData] = useState([])
  const [listUsers, setListusers] = useState(false)
  const [showFilters, setShowFilters] = useState(false)
  const [showMap, setShowMap] = useState(false)
  const [uploadModal, setUploadModal] = useState(false)
  const [uploading, setUploading] = useState(false)
  const [mapModal, setMapModal] = useState(false)
  const [compareModal, setCompareModal] = useState(false)

  // const [shouldFetchData, setShouldFetchData] = useState(true)
  const [closeFilterMenu, setCloseFilterMenu] = useState(true)
  const [filterCount, setFilterCount] = useState(1)
  const [conditionCount, setConditionCount] = useState(0)
  const [subConditionCount, setSubConditionCount] = useState(0)
  const [fetchData, setFetchData] = useState(true)
  const [pagination, setPagination] = useState({
    pageIndex: 0,
    pageSize: 10
  })
  const [sorting, setSorting] = useState([])
  const [rowSelection, setRowSelection] = useState({})

  const [filterData, setFilterData] = useState([
    {
      id: 'f-1',
      first: true,
      conditions: [
        {
          id: 'f-10',
          first: true,
          subConditions: [],
          filter: 'f-1'
        }
      ]
    }
  ])

  // Applying Filters
  const { data, loading, refetch } = useGetAllUsersDataQuery(
    {
      pagination,
      sorting,
      filters: JSON.stringify(filters)
    },
    { skip: fetchData }
  )
  // console.log(data)
  const handleApplyFilters = () => {
    setCurrentPage(0)
    setFilters(filterData)
    setCloseFilterMenu(true)
  }

  const handleResetFilters = () => {
    setCurrentPage(0)
    setFilterData([
      {
        id: 'f-1',
        first: true,
        conditions: [
          {
            id: 'f-10',
            first: true,
            subConditions: [],
            filter: 'f-1'
          }
        ]
      }
    ])
    setFilters([])
    setCloseFilterMenu(true)
  }

  // Moving on the next component
  const handleNext = () => {
    setUserIds(rowSelection)
    setStep(true)
    // console.log(rowSelection)
  }
  const handleUploadModal = bool => {
    setShowMap(bool)
    setUploadModal(true)
  }

  const handleExistingUserClick = () => {
    setFetchData(false)
    setListusers(true)
    setShowFilters(true)
  }

  useEffect(() => {
    if (data) {
      setUsersData(data.users)
    }
  }, [data, currentPage,usersData])
  console.log("myyyy ddddaaattttaaa",usersData)

  return (
    <Box>
      <Typography sx={subHeading}>Step 1</Typography>
      <Box
        sx={{
          display: 'flex',
          justifyContent: 'space-between',
          alignItems: 'center'
        }}
      >
        <Typography sx={subHeadingText}>Select Users</Typography>

        {listUsers && showFilters && (
          <FilterDrawer
            close={closeFilterMenu}
            setClose={setCloseFilterMenu}
            filters={filters}
          >
            <ConditionalFilters
              onReset={handleResetFilters}
              onSubmit={handleApplyFilters}
              filtersArray={filterData}
              setFiltersArray={setFilterData}
              setCloseFilterMenu={setCloseFilterMenu}
              filterCount={filterCount}
              setFilterCount={setFilterCount}
              conditionCount={conditionCount}
              setConditionCount={setConditionCount}
              subConditionCount={subConditionCount}
              setSubConditionCount={setSubConditionCount}
            />
          </FilterDrawer>
        )}
      </Box>
      {!listUsers && (
        <Grid
          container
          justifyContent='center'
          rowSpacing={1}
          columnSpacing={{ xs: 1, sm: 2, md: 3 }}
        >
          <Grid item justifyContent='center' xs={3}>
            <Button
              startIcon={<UploadFileIcon />}
              color='secondary'
              variant='contained'
              sx={{ color: '#F3F3F3' }}
              onClick={handleExistingUserClick}
            >
              Select from Existing users
            </Button>
          </Grid>
          <Divider />
          <Grid justifyContent='center' item xs={3}>
            <Button
              startIcon={<UploadFileIcon />}
              color='primary'
              variant='contained'
              sx={{ color: '#F3F3F3' }}
              onClick={() => handleUploadModal(true)}
            >
              Upload Csv
            </Button>
          </Grid>
        </Grid>
      )}

      {loading ? (
        <div className='noData'>
          <Loader />
        </div>
      ) : (data && listUsers || usersData.length > 0) ? (
        <>
          <Box sx={gridContainer}>
            <DataTable
              columns={userListColumns}
              data={usersData}
              selectable={true}
              totalData={data?.total || usersData.length}
              sorting={sorting}
              setSorting={setSorting}
              pagination={pagination}
              manualPagination = {!data ? false : true}
              setPagination={setPagination}
              rowSelection={rowSelection}
              setRowSelection={setRowSelection}
            />
          </Box>
          <Box sx={buttonsContainer}>
            <SecondaryButton text={'Back'} onClick={() => navigate(-1)} />
            <PrimaryButton
              text={'Next'}
              onClick={handleNext}
              disabled={Object.keys(rowSelection).length === 0}
            />
          </Box>
        </>
      ) : (
        <></>
      )}

      <Upload
        open={uploadModal}
        setOpen={setUploadModal}
        setMapModal={setMapModal}
        showMap={showMap}
        setShowMap={setShowMap}
        BtnText={'Upload Csv'}
        uploading={uploading}
        setUploading={setUploading}
        refetchData={refetch}
      />
      <MappingModal
        open={mapModal}
        setOpen={setMapModal}
        setFetchData = {setFetchData}
        setUploading={setUploading}
        setUploadModal={setUploadModal}
        setUsersData={setUsersData}
        setListusers={setListusers}
        setSelectedUsers = {setRowSelection}
      />
      <CompareModal open={compareModal} setOpen={setCompareModal} />
    </Box>
  )
}
