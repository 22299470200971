import React, { useEffect, useState } from 'react'
import { FormControl, Box, Typography, Alert } from '@mui/material'
import TextField from '@mui/material/TextField'
import PrimaryButton from '../../UIKit/Buttons/Primary'
import SecondaryButton from '../../UIKit/Buttons/Secondary'
import WarningButton from '../../UIKit/Buttons/Warning'
import RichTextEditor from '../../UIKit/RichTextEditor'
import { useNavigate } from 'react-router-dom'
import { formContainer, row } from './styles'
import MuiSelect from '../../UIKit/Select'
import BasicDatePicker from '../../UIKit/DatePicker'
import { subHeading, subHeadingText } from '../styles'
import { useCreateCampaignMutation } from '../../../RTKQuery/CampaignService/campaignApi'
import { changeDateFormat } from '../../../utils/helperFunctions'
import { formatString } from '../../../utils/helperFunctions'

export default function NewCampaignForm ({ setStep, step1Data }) {
  const navigate = useNavigate()
  const [bodyData, setBodyData] = useState('')
  const [alert, setAlert] = useState(false)
  const [createCampaign] = useCreateCampaignMutation()
  const [formData, setFormData] = useState({
    owner: '',
    startDate: null,
    endDate: null,
    clientProspect: '',
    campaign: '',
    industry: '',
    approved: '',
    campaignTitle: ``,
    campaignSubject: '',
    campaignBody: bodyData,
    users: step1Data
  })

  const onSubmit = async () => {
    if (Object.values(formData).some(value => value === '')) {
      setAlert(true)
      return
    }
    try {
      await createCampaign(formData)
      navigate('/campaigns')
    } catch (error) {
      console.log(error)
    }
  }

  const handleChange = event => {
    const { name, value } = event.target
    setFormData(prevData => ({
      ...prevData,
      [name]: value
    }))
  }

  const handleRichTextChange = value => {
    setBodyData(value)
    setFormData(prevData => ({
      ...prevData,
      campaignBody: value
    }))
  }

  const handleSubmit = () => {
    onSubmit(formData)
  }

  const handleBack = () => {
    // navigate(-1)
    setStep(false)
  }

  const handleTitleChange = event => {
    const { name, value } = event.target

    // ... (other code)

    // Update campaignTitle when relevant fields change
    if (name === 'industry' || name === 'startDate') {
      setFormData(prevData => ({
        ...prevData,
        campaignTitle: formatString(
          `${formData.industry}_campaign_${
            changeDateFormat(formData.startDate) || ''
          }`
        )
      }))
    }
  }

  const ownerOptions = ['Mustafa', 'other']
  const clientProspectOptions = ['Cold Prospect', 'Other']
  const industryOptions = ['Software', 'Retail & e-commerce']
  const approvedOptions = ['Approved', 'Not Approved']

  useEffect(() => {
    setFormData(prevData => ({
      ...prevData,
      campaignTitle: formatString(
        `${formData.industry}_campaign_${
          changeDateFormat(formData.startDate) || ''
        }`
      )
    }))
  }, [formData.industry, formData.startDate])

  return (
    <Box sx={{ margin: '1rem 0' }}>
      <Typography sx={subHeading}>Step 2</Typography>
      <Typography sx={subHeadingText}>Add Campaign Info</Typography>
      {alert && <Alert severity='warning'>Please fill all fields.</Alert>}
      <form style={formContainer}>
        <Box sx={{ flex: 1 }}>
          <FormControl fullWidth margin='normal'>
            <MuiSelect
              label={'Owner'}
              id='owner'
              name='owner'
              options={ownerOptions}
              required
              value={formData.owner}
              onChange={e =>
                setFormData({ ...formData, owner: e.target.value })
              }
              size='small'
            />
          </FormControl>
          <Box sx={row}>
            <FormControl fullWidth margin='normal'>
              <BasicDatePicker
                label='Start Date'
                onChange={e => setFormData({ ...formData, startDate: e })}
                value={formData.startDate}
              />
            </FormControl>
            <FormControl fullWidth margin='normal'>
              <BasicDatePicker
                label='End Date'
                onChange={e => setFormData({ ...formData, endDate: e })}
                value={formData.endDate}
              />
            </FormControl>
          </Box>
          <FormControl fullWidth margin='normal'>
            <MuiSelect
              label='Client Prospect'
              id='clientProspect'
              name='clientProspect'
              required
              options={clientProspectOptions}
              value={formData.clientProspect}
              onChange={e =>
                setFormData({ ...formData, clientProspect: e.target.value })
              }
              size='small'
            />
          </FormControl>
          <FormControl fullWidth margin='normal'>
            <TextField
              label='Campaign'
              id='campaign'
              name='campaign'
              required
              value={formData.campaign}
              onChange={handleChange}
              aria-describedby='subject-helper-text'
              size='small'
            />
          </FormControl>
          <FormControl fullWidth margin='normal'>
            <MuiSelect
              label='Industry'
              id='industry'
              name='industry'
              required
              options={industryOptions}
              value={formData.industry}
              onChange={e =>
                setFormData({ ...formData, industry: e.target.value })
              }
              size='small'
            />
          </FormControl>
          <FormControl fullWidth margin='normal'>
            <MuiSelect
              label='Approved By Daniel'
              id='approved'
              name='approved'
              required
              options={approvedOptions}
              value={formData.approved}
              onChange={e =>
                setFormData({ ...formData, approved: e.target.value })
              }
              size='small'
            />
          </FormControl>
        </Box>
        <Box sx={{ flex: 2 }}>
          <FormControl fullWidth margin='normal'>
            <TextField
              label='Campaign Title'
              id='campaignTitle'
              name='campaignTitle'
              required
              value={formData.campaignTitle}
              onChange={handleTitleChange}
              aria-describedby='title-helper-text'
              size='small'
              disabled
            />
          </FormControl>
          <FormControl fullWidth margin='normal'>
            <TextField
              label='Campaign Subject'
              id='campaignSubject'
              name='campaignSubject'
              required
              value={formData.subject}
              onChange={handleChange}
              aria-describedby='subject-helper-text'
              size='small'
            />
          </FormControl>
          <FormControl fullWidth margin='normal'>
            <RichTextEditor value={bodyData} onChange={handleRichTextChange} />
          </FormControl>
        </Box>
      </form>
      <Box sx={{ display: 'flex', alignItems: 'center', gap: '1rem' }}>
        <WarningButton text={'Cancel'} onClick={() => navigate('/campaigns')} />
        <SecondaryButton text={'Back'} onClick={handleBack} />
        <PrimaryButton
          text={'Submit'}
          onClick={handleSubmit}
          // disabled={Object.values(formData).some(value => value === '')}
        />
      </Box>
    </Box>
  )
}
