import { createSlice } from '@reduxjs/toolkit'

export const csvDataSlice = createSlice({
  name: 'file',
  initialState: {
    campaignUsers: []
  },
  reducers: {
    setCampaignUsersData: (state, action) => {
      state.Users = action.payload
    }
  }
})

export const { setCampaignUsersData } = csvDataSlice.actions
export default csvDataSlice.reducer
